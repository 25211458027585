import React from "react";

// <--------- Importing Components from React-bootstrap --------->
import { Container, Nav, Navbar } from "react-bootstrap";

// <--------- Importing Images --------->

// <--------- Importing Essential components --------->
import ButtonWithDownArrow from "../Buttons/ButtonWithArrow";
import Button from "../Buttons/Button";

// <--------- Importing Navbar style sheets ---------->
import Style from "./Navbar.module.css";
import { useLocation } from "react-router-dom";
import classNames from "classnames";

import useWindowDimensions from "../../utils/useWindowDimension";
import { logo, logo1 } from "../../assets/images";

const NavbarComponent = () => {
  const location = useLocation();
  const { width } = useWindowDimensions();
  return (
    <Navbar bg="rgb(47, 46, 123)" expand="lg">
      <Container>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />

        {/*<--------- App Logo Starts ----------> */}

        <Navbar.Brand href="/">
          <img className={Style.logo1} src={logo1} alt="logo" />
        </Navbar.Brand>
        {width < 770 && (
          <ButtonWithDownArrow
            name="Buy Insurance"
            bg={width > 770 ? "black" : "#FBD105"}
            color="white"
            width={width < 330 ? "124px" : ""}
            screenWidth={width}
            fontSize={width < 330 && "14px"}
            padding={width < 330 && "10px 0px 10px 0px"}
          />
        )}
        {/*<--------- App Logo Ends ------------> */}

        {/*<--------- Menus Starts -------------> */}

        <Navbar.Collapse className="navbar-expand-md" id="basic-navbar-nav">
          <Nav className="inline-flex justify-content-end w-100">
            <div
              className={classNames(
                width > 770 ? `d-flex` : ``,
                Style.header_navs
              )}
              style={{
                width: width > 770 ? (width < 1025 ? "55%" : "45%") : "100%",
              }}
            >
              <Nav.Link href="/" active={location.pathname === "/"}>
                Home
              </Nav.Link>

              <Nav.Link
                href="/products"
                active={location.pathname === "/products"}
              >
                Products
              </Nav.Link>

              <Nav.Link href="/faqs" active={location.pathname === "/faqs"}>
                FAQs
              </Nav.Link>

              <Nav.Link
                href="/contact"
                active={location.pathname === "/contact"}
              >
                Contact
              </Nav.Link>
              <a href="/forms" style={{ textDecoration: "none" }}>
                <Button
                  color="yellow"
                  bg={width > 770 ? "#FBD105" : "#FBD105"}
                  name="Get Insured"
                  size={16}
                  weight={700}
                  marginT={0}
                  url="/forms"
                  width={width > 430 ? 180 : 100}
                  borderTRR={25}
                  borderBLR={25}
                  borderBRR={25}
                  borderTLR={25}
                  black={width > 430 ? false : true}
                  isHeader={true}
                ></Button>
              </a>
            </div>
          </Nav>
        </Navbar.Collapse>

        {/*<--------- Menus Ends ----------> */}
      </Container>
    </Navbar>
  );
};

export default NavbarComponent;
