// default imports for react project
import React from "react";

// import default styles from react-bootstrap package
import { Col, Row } from "react-bootstrap";

// Import icon from assets folder
// import {
//   EmailIcon,
//   PhoneIphoneRoundedIcon,
//   YouTubeIcon,
//   LinkedInIcon,
//   InstagramIcon,
//   TwitterIcon,
//   FacebookIcon,
// } from "assets/icons";
import {
  FacebookRounded,
  LinkedIn,
  Mail,
  Phone,
  Twitter,
  WhatsApp,
} from "@mui/icons-material";

// import classname to combine module css and normal css
import classNames from "classnames";

// import stylesheet
import Style from "./Navbar.module.css";
import { useSelector } from "react-redux";

// React functional component starts here..
function TopNavbar() {
  const companyDetails = useSelector(
    (state) => state.auth.companyDetails?.companyContactViewModel
  );

  return (
    <div className={classNames(Style.topnavbar, " py-2")}>
      <Row className={classNames(Style.topnavbar_row, "container p-0 ")}>
        <Col lg={2} md={3} sm={12} className="d-flex appbar1__content">
          <div>
            <Phone />
          </div>
          <div className="mx-2">
            {companyDetails?.contactNumber
              ? companyDetails?.contactNumber
              : "+267 3643300"}
          </div>
        </Col>
        <Col lg={4} md={4} sm={12} className="d-flex appbar1__content">
          <div>
            <Mail />
          </div>
          <div className="mx-2">
            {companyDetails?.contactEMail
              ? companyDetails?.contactEMail
              : "Eswatini.Communications@letshego.com"}
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default TopNavbar;
