import { Divider } from '@mui/material';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import style from '../components.module.css';


const Summary = ({
  title,
  referenceNumber,
  isSummary = false,
  policyHolder,
  coverType,
  policyHolderSurname,
  premiumAmount,
  policyType,
  familyPolicy,
  coverAmount,
  isSuccess = false,
  isFail = false,
  isPass = false,
  isPending = false,
}) => {
  return (
    <div className={style.summary_container}>
      {/* {!isSummary && (
          <Row className="justify-content-center">
            <Col lg={12}>
              <p className="primary-color f-800 text-center">
                Your Policy has been emailed to you (please check you spam
                folder)
              </p>
            </Col>
          </Row>
        )} */}
      {isFail ? (
        <Row className="justify-content-center">
          <Col lg={12}>
            <h3 className="primary-color f-700 text-center">
              Transaction Failed
            </h3>
            <p className="primary-color f-700 text-center">
              We seem to struggle with the payment transaction.
            </p>
          </Col>
        </Row>
      ) : isPass ? (
        <Row className="justify-content-center">
          <Col lg={12}>
            <h3 className="primary-color f-700 text-center">All Done!</h3>
            {/* <p className="primary-color f-700 text-center">
              Thanks, payment received
            </p> */}
            <p className="primary-color f-700 text-center">
              Payment successful
            </p>
          </Col>
        </Row>
      ) : isPending ? (
        <Row className="justify-content-center">
          <Col lg={12}>
            <h3 className="primary-color f-700 text-center">All Done!</h3>
            <p className="primary-color f-700 text-center">Payment Pending</p>
            <p className="primary-color f-600 text-center">
              You will receive the policy confirmation email upon payment
              confirmation
            </p>
          </Col>
        </Row>
      ) : (
        <></>
      )}

      <Row className="justify-content-between mt-4">
        <Col>
          <p className="primary-color f-700 text-left">Reference number :</p>
        </Col>
        <Col>
          <p className="primary-color f-700 text-right w-100">
            {referenceNumber}
          </p>
        </Col>
      </Row>

      <Row className="justify-content-between mt-0">
        <Col>
          <p className="primary-color f-700 text-left">Policy holder :</p>
        </Col>
        <Col>
          <p className="primary-color f-700 text-right w-100">
            {policyHolder} {policyHolderSurname}
          </p>
        </Col>
      </Row>

      <Row className="justify-content-between mt-0">
        <Col>
          <p className="primary-color f-700 text-left">
            {policyType === 0 ? "Individual" : "Family"} policy :
          </p>
        </Col>
        <Col>
          <p className="primary-color f-700 text-right w-100">
            {coverType === "Basic" ? "Basic" : "Comprehensive"}
          </p>
        </Col>
      </Row>

      <Row className="justify-content-between mt-0">
        <Col>
          <p className="primary-color f-700 text-left">Cover Amount :</p>
        </Col>
        <Col>
          <p className="primary-color f-700 text-right w-100">{coverAmount}</p>
        </Col>
      </Row>

      <Divider />

      <Row className="justify-content-between mt-4">
        <Col>
          <p className="primary-color f-700 text-left">Monthly Premium :</p>
        </Col>
        <Col>
          <p className="primary-color f-700 text-right w-100">
            SZL {premiumAmount}
          </p>
        </Col>
      </Row>

      <Divider />

      <Row className="justify-content-start mt-3">
        <Col lg={12}>
          <p className="primary-color f-700 text-left m-0 w-100">
            Support contact details:
          </p>
          <ul>
            <li className="primary-color f-500 text-left m-0 w-100">
              llsOpsClaims@libertylife.co.sz
            </li>
            <li className="primary-color f-500 text-left m-0 w-100">
              +268 2409 5700
            </li>
          </ul>
        </Col>
      </Row>

      {!isSummary && (
        <>
          <Divider />
          <Row className="justify-content-start mt-3">
            <p className="primary-color f-700 text-center">
              Use the navigation bar on the left to return to the home page
            </p>
          </Row>
        </>
      )}
    </div>
  );
};


export default Summary;