/* eslint-disable jsx-a11y/anchor-is-valid */
// default imports for react project
import React, { useState } from "react";

// import default style component from react-bootstrap package
import { Col, Container, Row, Button } from "react-bootstrap";

// Import image from assets folder
import {
  comingSoon,
  main,
  FullMain,
  liberty,
  dateIcon,
} from "../../assets/images";

//   import custom component
import BodyButton from "../../components/Buttons/BodyButton";
import Card2 from "../../components/Card/Card2";
import { DateInput } from "../../components";

// import util to get current window dimensions
import useWindowDimensions from "../../utils/useWindowDimension";

import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import {
  getProductRisks,
  getProductRisksAttributes,
  updatedAttributes,
  getProductTypeDetails,
  getProductAttachments,
  updateCustomAttributes,
} from "../../store/actions/products";
import { getAgentOnboarding, login } from "../../store/actions/auth";
import { ATTRIBUTES, RISKS, CONFIG } from "../../utils/constants";
import { calculator } from "../../components/calculator";
import Loader from "../../components/Loader";
import moment from "moment";
import { toast } from "react-toastify";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { parseQuery, setParam, trackUserEvents } from "../../utils/utils";

// React functional component starts here..
function Disclosure({ isHomeLoading }) {
  // scroll window to top of page on navigation
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Get width from util
  const { width } = useWindowDimensions();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    // dispatch(clearState());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);
  const history = useNavigate();
  const currentToken = useSelector((state) => state.auth.currentUserToken);

  const agentCode = useSelector((state) => state.auth.agentCode);

  const [errors, setErrors] = useState({});

  const agentOnBoarding = useSelector((state) => state.auth.agentOnboarding);

  const [isLoading, setIsLoading] = useState(false);

  const [homeLoading, setHomeLoading] = useState(false);

  const state = useSelector((state) => state.products.riskAttributes);
  //const countDown = useSelector((state) => state?.products?.countDown.count);

  // Risk from the Product
  const risks = useSelector((state) =>
    state.products?.risks?.risks?.sort((x, y) =>
      x.sequence > y.sequence ? 1 : -1
    )
  );

  // Risk attributes from the Product i.e. BUD, CEU, ICU, etc.
  const risksAttributes = useSelector((state) =>
    state.products?.riskAttributes?.sort((x, y) =>
      x.sequence > y.sequence ? 1 : -1
    )
  );

  useEffect(() => {
    dispatch({
      type: "CURRENT_USER_TOKEN",
      payload: parseQuery(location.search).token ?? currentToken,
    });
  }, []);

  useEffect(() => {
    setParam(history, currentToken);
  }, [currentToken]);

  const reduxState = useSelector((state) => state);
  const riskAttributes = useSelector(
    (state) =>
      state?.products?.riskAttributes?.find((x) => x.code === "QUQ")?.attributes
  );

  const [isCounter, setIsCounter] = useState(false);
  let timer = null;

  return (
    <div style={{ backgroundColor: "#E5E5F5" }}>
      <Container>
        <Row>
          <div
            style={{
              color: "#2f2e80",
              fontSize: "32px",
              marginTop: "25px",
              fontWeight: "700",
            }}
          >
            {" "}
            Disclaimer{" "}
          </div>
        </Row>{" "}
        <Row>
          <p
            style={{
              color: "#2f2e80",
            }}
          >
            All rights reserved. All company names, products, registered and /
            or unregistered trademarks and copyrights assign to their respective
            companies within the Letshego Holdings Limited Group of companies
            (Letshego Holdings Limited and its subsidiaries and / or the Group).
            The information contained on this website, and on any attachments,
            is protected by law. By viewing the pages of this website, or any
            attachments, you acknowledge that you have read and consent to the
            terms of this disclaimer. The Group does not intend to contract or
            otherwise create legally binding obligations via the internet or by
            email. The information contained on this website, and any
            attachments, is provided for general information purposes.
          </p>
        </Row>{" "}
        <Row>
          <p
            style={{
              color: "#2f2e80",
            }}
          >
            The Group makes no warranties of any kind regarding the information,
            products and services advertised on this site. The Group will use
            reasonable efforts to ensure that all information displayed is
            accurate; however, the Group expressly disclaims any representation
            and warranty, expressed and implied, including, without limitation,
            warranties of merchantability, fitness for a particular purpose,
            suitability, and the ability to use the site without contracting a
            computer virus. The Group is not responsible for any loss, damage,
            expense, or penalty (either in tort, contract, or otherwise),
            including direct, indirect, consequential and incidental damages,
            that result from the access to, or use of, this site. This
            disclaimer includes, but is not limited to, the omission of
            information, the failure of equipment, the delay or inability to
            receive or transmit information, the delay or inability to print
            information, the transmission of any computer virus, or the
            transmission of any other malicious or disabling code or procedure.
          </p>
        </Row>{" "}
        <Row>
          <p
            style={{
              color: "#2f2e80",
            }}
          >
            This disclaimer is governed by the laws of Botswana, and all other
            countries in which Letshego subsidiaries are registered
            institutions, and will replace (or supercede) any terms contained in
            any correspondence received by Letshego, its directors, employees
            and representatives, insofar as these conflict with this disclaimer.
          </p>
        </Row>{" "}
        <Row>
          <p
            style={{
              color: "#2f2e80",
            }}
          >
            This disclaimer may be changed from time to time by posting a new
            Disclaimer, Terms of Use or Privacy Policy on the website. All users
            agree to be subject to these policies as they are updated from time
            to time.
          </p>
        </Row>{" "}
        <div style={{ height: "200px" }}></div>
      </Container>
    </div>
  );
}

export default Disclosure;
