// default imports for react project
import React from "react";

// import classname package to combine module css and normal css
import classNames from "classnames";

//   import custom component
import style from "./Button.module.css";

// React functional component starts here..
function ButtonWithDownArrow({
  name,
  bg,
  screenWidth,
  buttonType,
  color,
  float,
  fontSize,
  width,
  padding,
  onClick,
  type,
}) {
  return (
    <button
      onClick={onClick}
      type={buttonType && buttonType}
      style={{
        background: `${bg}`,
        color: `${color}`,
        borderRadius: "5px",
        boxShadow: "0px 3px 8px #00000059",
        float: float,
        padding: padding,
        height: 40,
        width: width,
        fontSize: fontSize,
      }}
      className={classNames(
        `${screenWidth > 330 ? 'px-3 py-2' : ''} d-flex`,
        style.body__btn__hover,
        style.button__header
      )}
    >
      <div className="mx-2">{type && type === "left" && "←"}</div>
      <div>{name}</div>
      <div className="mx-2">{type && type === "right" && "→"}</div>
    </button>
  );
}

export default ButtonWithDownArrow;
