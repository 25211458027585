// import classNames from "classnames";
// import React, { useState } from "react";
// import { useSelector } from "react-redux";
// import Summary from "../../../components/Forms/Summary";
// import { ATTRIBUTES, RISKS } from "../../../utils/constants";
// import useWindowDimensions from "../../../utils/useWindowDimension";
// import style from "../Form.module.css";
// import { liberty} from '../../../assets/images/index';


// const PaymentSuccess = () => {

//     const { width } = useWindowDimensions();
//     document.body.style = 'background: #3C409610;';
//     const policy = useSelector((state) => state?.prevState?.policy);
//     const riskAttributes = useSelector((state) => state?.prevState?.riskAttributes);

//     const redirectURL = useSelector((state) => state?.auth?.refererData?.redirectUrl);
//     const premiumAmount = useSelector((state) => state?.prevState?.premium);
//     const mainMember = useSelector((state) => state?.prevState?.mainMember);

//     return (
//         <div className="container form-container mt-lg-5 mb-5 p-0">
//             <div className="d-flex justify-content-center flex-column">
//                 {width > 770 ? (
//                     <>
//                         {/* <------- HEADER --------> */}
//                         <h3 className={style.header}>
//                             Instant Funeral Cover in a few clicks
//                         </h3>
//                     </>
//                 ) : null}



//                 <div className={classNames("container", style.boxBorder)}>
//                     <div className="mt-4 h-100">
//                         {width > 770 ? (
//                             <>
//                                 <div className="d-flex justify-content-center align-items-center flex-column">
//                                     <h3 className="primary-color f-700">Sisekelo Funeral Plan</h3>
//                                     <h5 className="primary-color f-700">Summary</h5>
//                                 </div>
//                                 <div
//                                     style={{
//                                         width: "100%",
//                                         height: "1px",
//                                         backgroundColor: "#00000020",
//                                     }}
//                                 ></div>
//                             </>
//                         ) : (
//                             <div
//                                 className={classNames(
//                                     "d-flex justify-content-start align-items-start flex-column",
//                                     style.headerContainer
//                                 )}
//                             >
//                                 <p className="text-white f-500 m-0 ">Sisekelo Funeral Plan</p>
//                                 <h5 className="primary-color f-700">Summary</h5>
//                             </div>
//                         )}
//                         <div className='d-flex justify-content-center flex-column align-items-center mt-5 mb-5'>
//                             <Summary 
//                                 title="Sisekelo Funeral Plan"
//                                 referenceNumber={policy?.policies && policy?.policies[0]?.policyReference}
//                                 policyHolder={mainMember?.firstName}
//                                 policyHolderSurname={mainMember?.lastName}
//                                 policyType={
//                                     riskAttributes
//                                     ?.find((y) => y.code === RISKS.UQQ)
//                                     ?.attributes?.find((x) => x.name === ATTRIBUTES.LIVES_COVERED)
//                                     ?.value
//                                   }
//                                   coverType={
//                                     riskAttributes
//                                     ?.find((y) => y.code === RISKS.UQQ)
//                                     ?.attributes?.find((x) => x.name === ATTRIBUTES.COVER_OPTIONS)
//                                     ?.value
//                                   }
//                                 premiumAmount={(premiumAmount)?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
//                                 familyPolicy={
//                                     riskAttributes
//                                       ?.find((y) => y.code === RISKS.UQQ)
//                                       ?.attributes?.find((x) => x.name === ATTRIBUTES.COVER_AMOUNT)
//                                       ?.value ?? 0
//                                   }
//                                   coverAmount={
//                                     riskAttributes
//                                       ?.find((y) => y.code === RISKS.UQQ)
//                                       ?.attributes?.find((x) => x.name === ATTRIBUTES.COVER_AMOUNT)
//                                       ?.value ?? 0
//                                   }
//                             />
//                             <div style={{maxWidth: '460px' ,width: '100%'}} className="d-flex justify-content-center flex-column">
//                             <img src={liberty} style={{width: '100px',display:"block",marginLeft:"auto",marginRight:"auto"}} />
//                             <p style={{fontSize: 13, textAlign: 'center'}}>
//                             Underwritten by Liberty Insurance Company Limited (Reg. No. R7/29653), a Licensed Life Insurer and an authorised Financial Services Provider
//                             </p>
//                         </div>
//                         </div>
//                     </div>
//                 </div>

//             </div>
//         </div>
//     );
// };
 
// export default PaymentSuccess;

///-----passing query parameters---------///////////
import classNames from "classnames";
import React, { useState, useEffect } from "react";
import { Route, useLocation, useNavigate } from "react-router-dom";
import {useDispatch, useSelector } from "react-redux";
import Summary from "../../../components/Forms/Summary";
import { ATTRIBUTES, RISKS } from "../../../utils/constants";
import useWindowDimensions from "../../../utils/useWindowDimension";
import style from "../Form.module.css";
import { liberty } from "../../../assets/images/index";
import {
  getPaymentStatus,
  issuePolicy,
  approvePolicy,
  rejectPolicy,
} from "../../../store/actions/products";
import { login } from "../../../store/actions/auth";

const PaymentSuccess = () => {
  const { width } = useWindowDimensions();
  const location = useLocation();
  const dispatch = useDispatch();

  document.body.style = "background: #3C409610;";
  const policy = useSelector((state) => state?.prevState?.policy);
  const riskAttributes = useSelector(
    (state) => state?.prevState?.riskAttributes
  );

  const redirectURL = useSelector(
    (state) => state?.auth?.refererData?.redirectUrl
  );
//   const premiumAmount = useSelector((state) => state?.prevState?.premium);
//   const mainMember = useSelector((state) => state?.prevState?.mainMember);


const parseQuery = (queryString) => {
  var query = {};
  var pairs = (
    queryString[0] === "?" ? queryString.substr(1) : queryString
  ).split("&");
  for (var i = 0; i < pairs.length; i++) {
    var pair = pairs[i].split("=");
    query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || "");
  }
  console.log("queryy-->", query);
  return query;
};
console.log(
  "location-->",
  parseQuery(location.search),
  parseQuery(location.search)?.referenceNumber,
);

useEffect(async () => {
  await dispatch(login());
  //----GetPaymentstatus call-----///
  const res = await dispatch(
    getPaymentStatus(
      parseQuery(location.search)?.referenceNumber,
      parseQuery(location.search)?.saleNumber
    )
  );
  console.log("resPaymentStatuss-->", res, res.genericCode);
  const code = res?.genericCode;
  console.log("code-->", code, parseQuery(location.search)?.paymentMethod);
  var splitCode = code.split("");
  if (parseQuery(location.search)?.paymentMethod === "Debit Order MP") {
  if (
    (splitCode[0] === "1" && splitCode[1] === "0") ||
    (splitCode[0] === "2" && splitCode[1] === "0")
  ) {
    const approveddata = {
      policyReference: parseQuery(location.search)?.referenceNumber,
      comment: "submitted from react front end",
    };
    await dispatch(approvePolicy(approveddata));
  } else {
    const rejecteddata = {
      externalReference: "LETSHEGO_SALE",
      comment: "Rejected from Letshego website",
      policyReference: parseQuery(location.search)?.referenceNumber,
    };
    await dispatch(rejectPolicy(rejecteddata));
  }
}
}, []);

  const resultCode = parseQuery(location.search)?.result;
  const splitResultCode = resultCode.split(".");
  console.log("resulttCode-->", resultCode.split("."), splitResultCode[0], splitResultCode[0] === "999");


  return (
    <div className="container form-container mt-lg-5 mb-5 p-0">
      <div className="d-flex justify-content-center flex-column">
        {width > 770 ? (
          <>
            {/* <------- HEADER --------> */}
            <h3 className={style.header}>
              Instant Funeral Cover in a few clicks
            </h3>
          </>
        ) : null}

        <div className={classNames("container", style.boxBorder)}>
          <div className="mt-4 h-100">
            {width > 770 ? (
              <>
                <div className="d-flex justify-content-center align-items-center flex-column">
                  <h3 className="primary-color f-700">Sisekelo Funeral Plan</h3>
                  <h5 className="primary-color f-700">Summary</h5>
                </div>
                <div
                  style={{
                    width: "100%",
                    height: "1px",
                    backgroundColor: "#00000020",
                  }}
                ></div>
              </>
            ) : (
              <div
                className={classNames(
                  "d-flex justify-content-start align-items-start flex-column",
                  style.headerContainer
                )}
              >
                <p className="text-white f-500 m-0 ">Sisekelo Funeral Plan</p>
                <h5 className="primary-color f-700">Summary</h5>
              </div>
            )}
            <div className="d-flex justify-content-center flex-column align-items-center mt-5 mb-5">
              <Summary
                title="Sisekelo Funeral Plan"
                isFail={(splitResultCode[0] !== "100" && splitResultCode[0] !== "200") ? true : false}
                isPass={splitResultCode[0] === "200" ? true : false}
                isPending={splitResultCode[0] === "100" ? true : false}                
                referenceNumber={parseQuery(location.search)?.referenceNumber}
                policyHolder={parseQuery(location.search)?.applicant}
                policyHolderSurname={parseQuery(location.search)?.lastName}
                policyType={parseInt(parseQuery(location.search)?.policyType)}
                coverType={parseQuery(location.search)?.coverType}
                premiumAmount={parseQuery(location.search)
                  ?.premium?.toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                // familyPolicy={
                //   riskAttributes
                //     ?.find((y) => y.code === RISKS.UQQ)
                //     ?.attributes?.find(
                //       (x) => x.name === ATTRIBUTES.COVER_AMOUNT
                //     )?.value ?? 0
                // }
                coverAmount={parseQuery(location.search)?.coverAmount}
                isSuccess={true}
                isSummary={true}
              />
              <div
                style={{ maxWidth: "460px", width: "100%" }}
                className="d-flex justify-content-center flex-column"
              >
                <img
                  src={liberty}
                  style={{
                    width: "100px",
                    display: "block",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                />
                <p style={{ fontSize: 13, textAlign: "center" }}>
                  Underwritten by Liberty Insurance Company Limited (Reg. No.
                  R7/29653), a Licensed Life Insurer and an authorised Financial
                  Services Provider
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentSuccess;