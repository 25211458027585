// default imports for react project
import React, { useLayoutEffect, useState } from "react";

// import default style component from react-bootstrap package
import { Col, Container, Row } from "react-bootstrap";

// Import image from assets folder
import { main, FullMain, liberty } from "../../assets/images";

//   import custom component
import BodyButton from "../../components/Buttons/BodyButton";
import Card2 from "../../components/Card/Card2";

// import util to get current window dimensions
import useWindowDimensions from "../../utils/useWindowDimension";

import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import {
  getProductRisks,
  getProductRisksAttributes,
  getProductTypeDetails,
} from "../../store/actions/products";
import { getAgentOnboarding, login } from "../../store/actions/auth";
import { calculator } from "../../components/calculator";
import Loader from "../../components/Loader";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { parseQuery, setParam, trackUserEvents } from "../../utils/utils";

// React functional component starts here..
function Homepage({ isHomeLoading }) {
  // scroll window to top of page on navigation
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Get width from util
  const { width } = useWindowDimensions();
  const dispatch = useDispatch();
  const history = useNavigate();
  const location = useLocation();
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);
  const [isLoading, setIsLoading] = useState(false);
  const [homeLoading, setHomeLoading] = useState(false);
  const currentToken = useSelector((state) => state.auth.currentUserToken);
  const agentCode = useSelector((state) => state.auth.agentCode);
  const agentOnBoarding = useSelector((state) => state.auth.agentOnboarding);
  const countDown =
    useSelector((state) => state?.products?.countDown?.count) ?? 0;

  // Risk from the Product
  const risks = useSelector((state) =>
    state.products?.risks?.risks?.sort((x, y) =>
      x.sequence > y.sequence ? 1 : -1
    )
  );

  // Risk attributes from the Product i.e. BUD, CEU, ICU, etc.
  const risksAttributes = useSelector((state) =>
    state.products?.riskAttributes?.sort((x, y) =>
      x.sequence > y.sequence ? 1 : -1
    )
  );
  useEffect(() => {
    dispatch({
      type: "CURRENT_USER_TOKEN",
      payload: parseQuery(location.search).token ?? currentToken,
    });
  }, []);

  useEffect(() => {
    setParam(history, currentToken);
  }, [currentToken]);

  const [isCounter, setIsCounter] = useState(false);
  let timer = null;
  // const [countDown, setCountDown] = useState(0);

  const handleSubmit = async (name) => {
    try {
      setIsLoading(true);
      setIsCounter(true);
      if (Object.keys(agentOnBoarding || {}).length === 0) {
        await dispatch(getAgentOnboarding(agentCode));
      }
      const riskRes = await dispatch(getProductRisks());
      await dispatch(getProductTypeDetails());
      riskRes?.risks?.forEach(async (risk, index) => {
        await dispatch(getProductRisksAttributes(risk.instanceId));

        if (riskRes?.risks?.length - 1 === index) {
          history("/forms");
          setIsLoading(false);
        }
      });
      setIsCounter(false);
      //trackUserEvents
      trackUserEvents(name, {});
      return;
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div>
      {width > 1024 ? (
        <Container>
          <div className="homepage-banner-pattern">
            <Row className="py-3 ">
              <Col lg={5} xs={12} className="text-center main__img_container">
                <img className="main__img" src={FullMain} alt="main" />
              </Col>
              <Col lg={7} xs={12} className="homepage__main__text">
                <Row className="homepage1__heading1 app__header__one m-0 justify-content-center">
                  Sisekelo Funeral Plan
                </Row>
                <Row
                  className="homepage1__heading2 m-0 pt-1 justify-content-center"
                  style={{ marginLeft: "16px" }}
                >
                  The loss of a loved one is a difficult and emotional time for
                  family members left behind
                </Row>
                <Row className="py-4 m-0 justify-content-center">
                  <Col xs={6} md={4} className="p-0">
                    {width > 700 && (
                      <BodyButton
                        color="white"
                        bg={width > 770 ? "#FBD105" : "#FBD105"}
                        name={width > 770 ? "Buy Cover Now" : "Buy Cover Now"}
                        onClick={() => {
                          handleSubmit("sz_insurnace_top_get_a_quote_cta");
                        }}
                        size={16}
                        weight={700}
                        marginT={0}
                        marginR={width > 770 ? 0 : 50}
                        width={width > 770 ? "180px" : "100%"}
                        align={width > 770 ? "left" : "center"}
                        black={width > 430 ? false : true}
                        borderTRR={25}
                        borderBLR={25}
                        borderBRR={25}
                        borderTLR={25}
                        br={100}
                        isHeader={true}
                        rounded={true}
                      />
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
          <Row className="mt-0">
            <Col sm={12}>
              <Card2 width={width} />
            </Col>
          </Row>
        </Container>
      ) : (
        <div className="homepage-banner-pattern">
          <Row
            className="py-3 justify-content-center"
            style={{ margin: "15px" }}
          >
            <Col lg={7} xs={12} className="homepage__main__text">
              <Row className="homepage1__heading1 app__header__one m-0 justify-content-center">
                Sisekelo Funeral Plan
              </Row>
              <Row
                className="homepage1__heading2 m-0 pt-1 justify-content-center"
                style={{ marginLeft: "16px" }}
              >
                The loss of a loved one is a difficult and emotional time for
                family members left behind
              </Row>
            </Col>
            <Col
              lg={5}
              xs={12}
              className="text-center main__img_container"
              style={{ marginBottom: "30px" }}
            >
              <img className="main__img" src={FullMain} alt="main" />
            </Col>
            <Row
              className="justify-content-center"
              style={{ backgroundColor: "#59599A", borderRadius: "5px" }}
            >
              <Col lg={7} xs={12} className="homepage__main__text">
                <Row className="py-4 m-0 justify-content-center">
                  <Col
                    xs={6}
                    md={4}
                    className="p-0"
                    style={{ position: "absolute", marginTop: "-45px" }}
                  >
                    {width < 700 && (
                      <BodyButton
                        color="#2F2E80"
                        bg={width < 770 ? "#FBD105" : "#2F2E80"}
                        name={width < 770 ? "Buy Cover Now" : "Buy Cover Now"}
                        onClick={() => {
                          handleSubmit(
                            "sz_insurnace_top_mobileView_get_a_quote_cta"
                          );
                        }}
                        size={16}
                        rounded={true}
                        weight={700}
                        marginT={0}
                        marginR={width < 770 ? 0 : 50}
                        borderTRR={25}
                        borderBLR={25}
                        borderBRR={25}
                        borderTLR={25}
                        width={width < 770 ? "auto" : "100%"}
                        align={width < 770 ? "left" : "center"}
                        black={width < 430 ? false : true}
                        br={100}
                        isHeader={true}
                      />
                    )}
                  </Col>
                </Row>
              </Col>

              <Row style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                <h4
                  style={{
                    fontSize: "20px",
                    color: "white",
                    fontWeight: "bold",
                    textAlign: "center",
                    paddingLeft: "0px",
                    paddingRight: "0px",
                  }}
                >
                  Sisekelo Funeral Plan
                </h4>
              </Row>
              <Row>
                <h6
                  style={{
                    fontSize: "10pt",
                    textAlign: "center",
                    color: "white",
                    fontWeight: "bold",
                  }}
                >
                  For Individual and family Plans <br />
                  Cover options to choose from
                  <br />
                </h6>
              </Row>
              <Row>
                <p
                  style={{
                    fontSize: "11pt",
                    textAlign: "center",
                    color: "white",
                    fontWeight: "5pt",
                  }}
                >
                  SZL 5,000 / SZL 10,000 <br />
                  SZL 20,000 / SZL 30,000 / SZL 50,000
                </p>
              </Row>

              <Row
                className="justify-content-center"
                style={{
                  fontSize: "11pt",
                  color: "white",
                  textAlign: "center",
                  marginBottom: "5px",
                  padding: "3px",
                  fontWeight: "bold",
                }}
              >
                <span>
                  There are two cover options,{" "}
                  <span style={{ color: "#FBD105" }}>
                    a Basic and a Comprehensive option
                  </span>
                  , so you can choose the one most suitable to your needs and
                  financial circumstance.
                </span>
              </Row>
              <Row
                className="justify-content-center"
                style={{
                  fontSize: "11pt",
                  color: "white",
                  textAlign: "center",
                  marginBottom: "5px",
                  marginTop: "11px",
                  fontWeight: "bold",
                }}
              >
                <span>
                  Each option covers a policyholder, one spouse and a maximum of
                  six children with a choice to add parents and parents-in-law
                  as well as extended family members.
                </span>
              </Row>
              <Row
                className="justify-content-center"
                style={{
                  fontSize: "11pt",
                  color: "white",
                  textAlign: "center",
                  marginBottom: "5px",
                  marginTop: "11px",
                  fontWeight: "bold",
                }}
              >
                <span>
                  All valid claims are paid in less than 48 hours after all the
                  required claim documents have been submitted.
                </span>
              </Row>
              <Row className="py-4 m-0 justify-content-center">
                <Col
                  xs={6}
                  md={4}
                  className="p-0"
                  style={{ position: "absolute", marginTop: "-45px" }}
                >
                  {width < 700 && (
                    <BodyButton
                      color="#2F2E80"
                      bg={width < 770 ? "#FBD105" : "#2F2E80"}
                      name={width < 770 ? "Buy Cover Now" : "Buy Cover Now"}
                      onClick={() => {
                        handleSubmit(
                          "sz_insurnace_bottom_mobileView_get_a_quote_cta"
                        );
                      }}
                      size={16}
                      rounded={true}
                      weight={700}
                      marginT={24}
                      marginR={width < 770 ? 0 : 50}
                      borderTRR={25}
                      borderBLR={25}
                      borderBRR={25}
                      borderTLR={25}
                      width={width < 770 ? "auto" : "100%"}
                      align={width < 770 ? "left" : "center"}
                      black={width < 430 ? false : true}
                      br={100}
                      isHeader={true}
                    />
                  )}
                </Col>
              </Row>
              <Row
                className="justify-content-center"
                style={{
                  fontSize: "11pt",
                  color: "white",
                  textAlign: "center",
                  marginTop: "8px",
                  fontWeight: "bold",
                  paddingLeft: "11px",
                  marginBottom: "25px",
                }}
              >
                From: SZL 21 / Monthly
              </Row>
              <hr
                style={{
                  color: "white",
                  height: 1,
                  width: "97%",
                  margin: "auto",
                }}
              />
              <Row
                className="justify-content-center"
                style={{
                  fontSize: "8pt",
                  color: "white",
                  textAlign: "center",
                  marginTop: "20px",
                  padding: "14px",
                  fontWeight: "bold",
                }}
              >
                By opting to buy insurance, Letshego will share the required
                information with the insurer to facilitate your policy purchase.
              </Row>
              <Row>
                <h6
                  style={{
                    color: "white",
                    fontSize: "8pt",
                    fontWeight: "bold",
                    textAlign: "center",
                    marginTop: "1px",
                  }}
                >
                  Policy underwritten by
                </h6>
              </Row>

              <Row>
                <div
                  className={`d-flex ${
                    width > 770
                      ? "justify-content-start"
                      : "justify-content-center"
                  }`}
                >
                  <img
                    src={liberty}
                    style={{
                      marginTop: "1px",
                      width: "95px",
                    }}
                    alt="Liberty Logo"
                  />
                </div>
              </Row>
            </Row>
          </Row>
        </div>
      )}
      {isLoading && (
        <div
          style={{
            height: "99vh",
            width: "100%",
            zIndex: 100,
            backgroundColor: "#00000050",
            position: "fixed",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            top: "0px",
          }}
        >
          <div style={{ height: 100, width: 100 }}>
            <CircularProgressbar
              styles={buildStyles({
                textColor: "#fbcf2c",
                pathColor: "#fbcf2c",
              })}
              value={countDown}
              text={`${countDown}%`}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default Homepage;

const data2 = [
  "There are two cover options, a Basic and a Comprehensive option, so you can choose the one most suitable to your needs and financial circumstance.",
  "Each option covers a policyholder, one spouse and a maximum of six children with a choice to add parents and parents-in-law as well as extended family members.",
  "All valid claims are paid in less than 48 hours after all the required claim documents have been submitted.",
];
