// import { Accordion, Divider } from "@mui/material";
// import classNames from "classnames";
// import React, { useState, useEffect, useCallback } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { useNavigate } from "react-router";
// import { toast } from "react-toastify";
// import {DateInput,TextInput,TwoButtons} from "../../../components";
// import Summary from "../../../components/Forms/Summary";
// import NavigationButtons from "../../../components/NavigationButtons";
// import { storeState } from "../../../store/actions/prevState";
// import {issuePolicy,addPolicyBankDetails, approvePolicy, sendOTP, validateOTP, UpdateSteps} from "../../../store/actions/products";
// import { ATTRIBUTES, RISKS } from "../../../utils/constants";
// import useWindowDimensions from "../../../utils/useWindowDimension";
// import style from "../Form.module.css";
// import AccordionSummary from "@mui/material/AccordionSummary";
// import AccordionDetails from "@mui/material/AccordionDetails";
// import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// import { validate } from "validate.js";
// import ReactSelect from "react-select";

// const Step4 = ({ handleNext, handleBack, files, handleFormBack }) => {
//   const [attributes, setAttributes] = useState({});
//   const [errors, setErrors] = useState({});

//   const navigate = useNavigate();
//   const dispatch = useDispatch();
//   const { width } = useWindowDimensions();
//   const [isLoading, setIsLoading] = useState(false);
//   const premiumAmount = useSelector((state) => state?.products?.premium);
//   const policy = useSelector((state) => state?.products?.policy);
//   const mainMember = useSelector((state) => state?.products?.mainMember);
//   const [terms, setTerms ]  = useState({
//       aggreement: null,
//       terms: false,
//       declaration:  false
//   })

//   const riskAttributes = useSelector(
//     (state) => state?.products?.riskAttributes
//   );
//   const activeStep = useSelector((state) => state.products.activeStep);
//   const [, updateState] = useState();
//   const forceUpdate = useCallback(() => {updateState({})},[])
//   const [monthList, setMonthList] = useState();
//   const [payDate, setPayDate] = useState();

//   useEffect(() => {
//     var optionsList = [
//       { value: "01", label: "January" },
//       { value: "02", label: "February" },
//       { value: "03", label: "March" },
//       { value: "04", label: "April" },
//       { value: "05", label: "May" },
//       { value: "06", label: "June" },
//       { value: "07", label: "July" },
//       { value: "08", label: "August" },
//       { value: "09", label: "September" },
//       { value: "10", label: "October" },
//       { value: "11", label: "November" },
//       { value: "12", label: "December" },
//     ];
//     // Get current month and calculate 3 months ahead
//     const today = new Date();
//     const currentMonth = today.getMonth();
//     const futureMonth = new Date(today.getFullYear(), currentMonth + 2, 1).getMonth();

//     // Generate options for the dropdown
//     // let options = [];
//     let months=[];
//     for (let i = currentMonth; i <= futureMonth; i++) {
//       const month = new Date(today.getFullYear(), i, 1).toLocaleString("default",{ month: "long" });
//       // months.push(month)
//         months.push({value: optionsList.find((x) => x.label === month).value, label:month})
//     }
//     console.log("Months-->", months);
//     setMonthList(months);
//   },[])

//   const handleChange = async(evt) => {
//     let obj ={...attributes,
//       [evt.target.name]: evt.target.value,
//     }
//     // setAttributes({
//     //   ...attributes,
//     //   [evt.target.name]: evt.target.value,
//     // });
//     setAttributes(obj)
//     firstPay(obj);
//   }
//   const firstPay = (obj) => {
//     if (obj?.coverDate && obj?.coverMonth) {
//       const today = new Date();
//       const currentYear = today.getFullYear();
//       const selectedMonth = obj.coverMonth?.value?.charAt(0) === '0' ? obj.coverMonth?.value?.replace('0','') : obj.coverMonth?.value;
//       var lastDay = new Date(currentYear, selectedMonth, 0).getDate();
//       var lastDebitOrder = `${currentYear}-${obj.coverMonth?.value}-${lastDay}`;
//       setPayDate(obj.coverDate?.label === "Last Day of the month" ? lastDebitOrder : `${currentYear}-${obj.coverMonth?.value}-${obj.coverDate?.value}`);
//     }
//   }

//   useEffect(() => {
//     if(!files || Object.keys(files).length === 0){
//       dispatch(UpdateSteps(activeStep-1));
//       handleFormBack(activeStep-1)
//       forceUpdate();

//       return;
//     };
//   },[])

//   const otpResponse = useSelector((state) => state?.products?.otpResponse);
//   const handleSubmit = async () => {
//     const today = new Date();
//     const currentYear = today.getFullYear();
//     const selectedMonth = attributes.coverMonth?.value?.charAt(0) === '0' ? attributes.coverMonth?.value?.replace('0','') : attributes.coverMonth?.value;
//     var lastDay = new Date(currentYear, selectedMonth, 0).getDate();
//     var lastDebitOrder = `${currentYear}-${attributes.coverMonth?.value}-${lastDay}`;
//     var errors = validate(attributes, debitSchema);
//     console.log('payDateee-->',payDate)
//     console.log('errrorss-->',errors)
//     // var payDate = '2023-02-08'
//     if(payDate){
//       //---Check date is the previous date or current date------//
//       const date = new Date(payDate);
//       const pastDay = new Date(date.toDateString()) < new Date(new Date().toDateString());
//       console.log("pastDay-->", pastDay);
//       if (pastDay === true) {
//         errors = { ...errors, [`paydate`]: [`Date cannot be in the past.`] };
//       }

//       if(pastDay === false){
//         var futureDate=new Date();   //Current date
//         var selectedDate = new Date(payDate); //Selected date by user
//         var todayDate = new Date(futureDate);
//         var nextWeek = new Date(todayDate.getFullYear(), todayDate.getMonth(), todayDate.getDate() + 7);
//         if (nextWeek > selectedDate) {
//           console.log("date present within week");
//           errors = { ...errors, [`paydate`]: [`Date cannot be within the next 7 days from present.`] };
//         }
//       }
//     }
//     if(!files || Object.keys(files).length === 0){
//       dispatch(UpdateSteps(activeStep-1));
//       handleFormBack(activeStep-1)

//       forceUpdate();
//       return;
//     };
//     if (errors) {
//     console.log("errrorss1111-->", errors);
//       setErrors(errors);
//       var err = Object.keys(errors);
//       const divID = document.getElementById(err[0]);
//       if(divID){
//       window.scrollTo({
//         top: divID.offsetTop,
//         behavior: "smooth",
//       });
//     }
//       return;
//     }
//       setIsLoading(true);
//       try{
//         const res1 = await dispatch(validateOTP({
//           key: otpResponse?.key,
//           password: attributes?.otp
//         }))
//         if(res1){
//           const data={externalReference: policy.externalReference,
//             policySaleReference: policy.policySaleReference,
//             comment: "Issue Sale from Web App",
//             policies: [
//               {
//                 policyReference:
//                 policy.policies?.length &&
//                 policy.policies[0].policyReference,
//                 bankAccounts: [
//                   {
//                     externalReference: "CREATE_BANK_100",
//                     type: attributes.type,
//                     pennyDropStatus: "verified",
//                     pennyDropMessage: "Verified by XYZ",
//                     bank: attributes.bank,
//                     accountNumber: attributes.accountNumber,
//                     accountHolder: attributes.accountHolder,
//                     branch: attributes.branch,
//                     branchCode: attributes.branchCode,
//                     iban: "IB-200",
//                     swift: "SW-200",
//                   },
//                 ],
//               },
//             ],
//             webhook: {
//               webhookUrl:
//                 "https://briisk-i-dev-policies.azurewebsites.net/webhook/saveWebhook",
//             },}

//         // const res = await dispatch(issuePolicy(data));

//         const data1 = {
//           type: attributes.type,
//           pennyDropStatus: "0",
//           pennyDropMessage: null,
//           bank: attributes.bank,
//           accountNumber: attributes.accountNumber,
//           accountHolder: attributes.accountHolder,
//           branch: attributes.branch,
//           iban: "IB-200",
//           swift: "SW-200",
//           policyCollectionSchedule: {
//             // collectionDay : attributes.coverDate.split("-")[2],
//             collectionDay: attributes.coverDate?.label === "Last Day of the month" ? lastDay : attributes.coverDate?.value,
//             collectionDuration: 0,
//             collectionFrequency: "3",
//             policyFirstCollection: {
//               firstCollectionAmount: premiumAmount,
//               FirstCollectionDate: attributes.coverDate?.label === "Last Day of the month" ? lastDebitOrder :  `${currentYear}-${attributes.coverMonth?.value}-${attributes.coverDate?.value}`,
//               IsTaxInclusive: true,
//               FirstCollectionTaxAmount: "0",
//               Comments:
//                 policy.policies?.length && policy.policies[0].policyReference,
//             },
//           },
//         };

//         const res = await dispatch(addPolicyBankDetails(policy.policySaleReference,policy.policies[0].policyReference,data1));
//         console.log('res->',res)
//         await dispatch(issuePolicy(data));

//         // const approveddata = {
//         //   policyReference: policy.policies[0].policyReference,
//         //   comment : "test for approve"
//         // }
//         // await dispatch(approvePolicy(approveddata));
//         // await dispatch(issuePolicy(data));
//         //  await dispatch(addPolicyBankDetails(policy.policySaleReference,policy.policies[0].policyReference,data1));
//           setIsLoading(false);
//           dispatch(storeState())
//           navigate('/payment-success')
//         }
//         else{
//           toast.error('Please enter valid OTP')
//           setIsLoading(false);
//         }
//       }
//       catch(err){
//         toast.error(attributes?.otp.length !== 4 ? 'Please enter valid OTP' : 'Please enter valid OTP')
//         console.log('err--step4--',err)
//         setIsLoading(false);
//       }
//   }

//   return (
//     <div className="mt-4">
//       {width > 770 ? (
//         <>
//           <div className="d-flex justify-content-center align-items-center flex-column">
//             <h3 className="primary-color f-700">Sisekelo Funeral Plan</h3>
//             <h2 className="primary-color f-800">Policy Application</h2>
//           </div>
//           <div
//             style={{
//               width: "100%",
//               height: "1px",
//               backgroundColor: "#00000020",
//             }}
//           ></div>
//         </>
//       ) : (
//         <div
//           className={classNames(
//             "d-flex justify-content-start align-items-start flex-column",
//             style.headerContainer
//           )}
//         >
//           <p className="text-white f-500 m-0 ">Sisekelo Funeral Plan</p>
//           <h2 className="primary-color f-800">Policy Application</h2>
//         </div>
//       )}

//       <div className="mt-4 h-100">
//         <div className="d-flex justify-content-center flex-column align-items-center mt-5 mb-5">
//           <Summary
//             isSummary={true}
//             title="Last Expense Funeral Cover"
//             referenceNumber={
//               policy?.policies && policy?.policies[0]?.policyReference
//             }
//             policyHolder={mainMember?.firstName ?? "NA"}
//             policyType={
//               riskAttributes
//                 ?.find((y) => y.code === RISKS.UQQ)
//                 ?.attributes?.find((x) => x.name === ATTRIBUTES.LIVES_COVERED)
//                 ?.value
//             }
//             coverType={
//               riskAttributes
//                 ?.find((y) => y.code === RISKS.UQQ)
//                 ?.attributes?.find((x) => x.name === ATTRIBUTES.COVER_OPTIONS)
//                 ?.value
//             }
//             policyHolderSurname={mainMember?.lastName ?? "NA"}
//             familyPolicy={
//               riskAttributes
//                 ?.find((y) => y.code === RISKS.UQQ)
//                 ?.attributes?.find((x) => x.name === ATTRIBUTES.COVER_AMOUNT)
//                 ?.value ?? 0
//             }
//             coverAmount={
//               riskAttributes
//                 ?.find((y) => y.code === RISKS.UQQ)
//                 ?.attributes?.find((x) => x.name === ATTRIBUTES.COVER_AMOUNT)
//                 ?.value ?? 0
//             }
//             premiumAmount={premiumAmount
//               ?.toString()
//               .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
//           />
//           <Accordion
//             defaultExpanded={true}
//             style={{ maxWidth: "460px", width: "100%" }}
//           >
//             <AccordionSummary
//               expandIcon={<ExpandMoreIcon />}
//               aria-controls="panel1a-content"
//               id="panel1a-header"
//             >
//               <div className="d-flex flex-row">
//                 <p className="f-700 p-0 m-0" style={{ color: "#3C4096" }}>
//                   Please enter your payment details here:
//                 </p>
//               </div>
//             </AccordionSummary>
//             <AccordionDetails className="p-0 mt-4">
//               <div
//                 style={{
//                   border: "1px solid #00000030",
//                   padding: 20,
//                   borderRadius: 5,
//                 }}
//               >
//                 <TextInput
//                   label="Account holder name*"
//                   placeholder="Full name"
//                   value={attributes.accountHolder}
//                   name="accountHolder"
//                   onChange={handleChange}
//                   errors={errors.accountHolder}
//                 />
//                 <TextInput
//                   label="Account number*"
//                   placeholder="Your account number"
//                   value={attributes.accountNumber}
//                   name="accountNumber"
//                   onChange={handleChange}
//                   errors={errors.accountNumber}
//                 />
//                 <TextInput
//                   title="Account type*"
//                   label="Account type*"
//                   value={attributes.type}
//                   name="type"
//                   placeholder=" Your account type"
//                   onChange={handleChange}
//                   errors={errors.type}
//                 />
//                 <TextInput
//                   title="Bank name*"
//                   label="Bank name*"
//                   value={attributes.bank}
//                   placeholder="Your bank name"
//                   name="bank"
//                   onChange={handleChange}
//                   errors={errors.bank}
//                 />
//                 <TextInput
//                   label="Branch name*"
//                   placeholder="Your branch name"
//                   value={attributes.branch}
//                   name="branch"
//                   onChange={handleChange}
//                   errors={errors.branch}
//                 />
//                 <TextInput
//                   label="Branch code*"
//                   placeholder="Your branch code"
//                   value={attributes.branchCode}
//                   name="branchCode"
//                   onChange={handleChange}
//                   errors={errors.branchCode}
//                 />
//                 <div className="form-group mt-3">
//                   <label className="primary-color f-700">
//                     Debit order date*
//                   </label>
//                   <ReactSelect
//                     id={`coverDate`}
//                     placeholder="Debit order date"
//                     value={attributes?.coverDate}
//                     options={[
//                       { value: "03", label: "3rd" },
//                       { value: "20", label: "20th" },
//                       { value: "25", label: "25th" },
//                       {
//                         value: "Last Day of the month",
//                         label: "Last Day of the month",
//                       },
//                     ]}
//                     onChange={(e) => {
//                       handleChange({
//                         target: { name: "coverDate", value: e },
//                       });
//                     }}
//                   />
//                   {errors?.["coverDate"] && (
//                     <span
//                       className="text-danger errors"
//                       // style={{ marginLeft: 10 }}
//                     >
//                       {errors?.["coverDate"] && errors?.["coverDate"][0]}
//                     </span>
//                   )}
//                 </div>
//                 {/* ///---Month list------/// */}
//                 <div className="form-group mt-3">
//                   <label className="primary-color f-700">
//                     Debit order month*
//                   </label>
//                   <ReactSelect
//                     id={`coverMonth`}
//                     placeholder="Debit order month"
//                     value={attributes?.coverMonth}
//                     options={monthList}
//                     onChange={(e) => {
//                       handleChange({
//                         target: { name: "coverMonth", value: e },
//                       });
//                     }}
//                   />
//                   {errors?.["coverMonth"] && (
//                     <span
//                       className="text-danger errors"
//                       // style={{ marginLeft: 10 }}
//                     >
//                       {errors?.["coverMonth"] && errors?.["coverMonth"][0]}
//                     </span>
//                   )}
//                 </div>

//                 {/* ///---FirstPaymentDate------/// */}
//                 <div className="form-group mt-3">
//                   <label className="primary-color f-700">
//                     First payment date*
//                   </label>
//                   <input
//                     type="text"
//                     // name={"FirstPaymentDate"}
//                     disabled={true}
//                     placeholder={"FirstPaymentDate"}
//                     className="form-control"
//                     value={payDate}
//                     // onChange={onChange}
//                   />
//                   {errors?.["paydate"] && (
//                     <span
//                       className="text-danger errors"
//                       // style={{ marginLeft: 10 }}
//                     >
//                       {errors?.["paydate"] && errors?.["paydate"][0]}
//                     </span>
//                   )}
//                 </div>
//                 {/* <DateInput
//                     label={'Debit order date'}
//                     name={'coverDate'}
//                     onChange={handleChange}
//                     required={true}
//                     value={attributes.coverDate}
//                     errors={errors.coverDate}
//                     minDate={new Date()}
//                 /> */}
//                 <p className="mt-3">
//                   I, the undersigned authorise Liberty to, in terms of the
//                   aggreement, deduct the premium for the amount as specified in
//                   this form, from this account, including any applicable premium
//                   increases I have selected or any increases that Liberty may
//                   apply as agreed with me, until the due premium on this policy
//                   is paid.
//                 </p>
//                 <TwoButtons
//                   isField={true}
//                   half={true}
//                   state={terms.aggreement}
//                   errors={errors["aggreement"]}
//                   onClick={(e) => setTerms({ ...terms, aggreement: e })}
//                   options={[
//                     {
//                       name: "Yes",
//                       value: 1,
//                     },
//                   ]}
//                 />
//                 <h6 className="mt-3">Declarations</h6>
//                 <p>
//                   I have read and understood the information for the
//                   policyholder & conditions of insurance made available to me/us
//                   and agree to accept the insurance to the terms conditions of
//                   exclusions and limitations.
//                   <br />
//                   <br />
//                   I confirm that I/am we/are residents of Eswatini
//                   <br />
//                   <br />I consent to provider, providing me with the information
//                   for the policyholder & conditions of insurance, the policy
//                   overview and the certificate of insurance via email
//                 </p>
//                 <TwoButtons
//                   isField={true}
//                   half={true}
//                   state={terms.declaration}
//                   errors={errors["declaration"]}
//                   onClick={(e) => setTerms({ ...terms, declaration: e })}
//                   options={[
//                     {
//                       name: "Yes",
//                       value: 1,
//                     },
//                   ]}
//                 />

//                 <Divider />

//                 <button
//                   className={style.requestOTP}
//                   onClick={() => {
//                     if (!terms?.declaration || !terms?.aggreement) {
//                       return setErrors({
//                         ...errors,
//                         declaration: !terms?.declaration
//                           ? ["Please review the declaration"]
//                           : [],
//                         aggreement: !terms?.aggreement
//                           ? ["Please review the aggreements"]
//                           : [],
//                       });
//                     } else {
//                       setErrors({
//                         ...errors,
//                         declaration: [""],
//                         aggreement: [""],
//                       });
//                     }
//                     dispatch(sendOTP())
//                       .then((res) => {})
//                       .catch((err) =>
//                         toast.error(err?.response?.data?.message)
//                       );
//                   }}
//                 >
//                   Request OTP
//                 </button>
//                 <TextInput
//                   id="otp"
//                   label=""
//                   name="otp"
//                   placeholder={"Enter your OTP"}
//                   value={attributes?.otp}
//                   errors={errors?.otp}
//                   onChange={(e) =>
//                     setAttributes({ ...attributes, otp: e.target.value })
//                   }
//                 />
//                 <span style={{ fontSize: "14px" }}>
//                   By entering the OTP, you accept the T&C’s and debit order
//                   mandate
//                 </span>
//                 <div className=" mt-3 d-flex justify-content-between flex-row align-items-start">
//                   <TwoButtons
//                     isField={true}
//                     half={true}
//                     onClick={(e) => setTerms({ ...terms, terms: e })}
//                     state={terms.terms}
//                     errors={errors?.["terms"]}
//                     options={[
//                       {
//                         name: "Yes",
//                         value: 1,
//                       },
//                     ]}
//                   />
//                   <p>
//                     By ticking this box. I accept that the premium for this
//                     policy will be collected monthly for payment. I further
//                     declare that I have read and understood the Payment Terms &
//                     Conditions.
//                   </p>
//                 </div>
//                 <Divider />
//                 <div style={{ height: "20px" }} />
//                 <button
//                   className={style.confirm_purchase}
//                   onClick={() => {
//                     if (
//                       !terms?.terms ||
//                       !terms?.aggreement ||
//                       !terms?.declaration
//                     ) {
//                       setErrors({
//                         ...errors,
//                         terms: !terms?.terms
//                           ? ["Please review the terms."]
//                           : [],
//                         declaration: !terms?.declaration
//                           ? ["Please review the declaration"]
//                           : [],
//                         aggreement: !terms?.aggreement
//                           ? ["Please review the aggreements"]
//                           : [],
//                       });
//                     } else {
//                       setErrors({
//                         ...errors,
//                         terms: [],
//                         declaration: [],
//                         aggreement: [],
//                       });
//                       handleSubmit();
//                     }
//                   }}
//                 >
//                   {isLoading ? (
//                     <div class="lds-ring">
//                       <div></div>
//                       <div></div>
//                       <div></div>
//                     </div>
//                   ) : (
//                     "Confirm Purchase"
//                   )}
//                 </button>
//               </div>
//             </AccordionDetails>
//           </Accordion>
//         </div>
//       </div>

//       <div style={{ height: "20px" }} />

//       <div className={style.step_1_price_summary}>
//         <div className={style.step_1_price_summary_sub}>
//           <h5 className="text-white f-700">Total Monthly Premium</h5>
//           <h5 className="text-white f-700">
//             SZL{" "}
//             {premiumAmount
//               ? premiumAmount?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
//               : 0 ?? 0}
//           </h5>
//         </div>
//       </div>

//       <NavigationButtons
//         back={handleBack}
//         payment={true}
//         isBackDisabled={isLoading}
//         next={() => {
//           if (!terms) {
//             return toast.error("Please confirm terms & conditions");
//           }
//           dispatch(storeState());
//           navigate("/payment-success");
//         }}
//       />
//       {isLoading && (
//         <div
//           style={{
//             position: "fixed",
//             top: 0,
//             left: 0,
//             width: "100%",
//             display: "flex",
//             backgroundColor: "rgba(0,0,0,0.1)",
//             justifyContent: "center",
//             alignItems: "center",
//             height: "100vh",
//           }}
//         >
//           {/* <Loader /> */}
//         </div>
//       )}
//     </div>
//   );
// };

// export default Step4;

// const debitSchema = {
//   accountHolder: {
//     presence: { allowEmpty: false },
//     format: {
//       pattern: "[a-zA-Z].+",
//       flags: "g",
//       message: "can only contain alphabets",
//     },
//   },
//   accountNumber: {
//     presence: { allowEmpty: false },
//     format: {
//       pattern: "^[0-9]+$",
//       message: "can only contain number",
//     },
//   },
//   type: {
//     presence: { allowEmpty: false },
//     format: {
//       pattern: "[a-zA-Z].+",
//       flags: "g",
//       message: "can only contain alphabets",
//     },
//   },
//   bank: {
//     presence: { allowEmpty: false },
//     format: {
//       pattern: "[a-zA-Z].+",
//       flags: "g",
//       message: "can only contain alphabets",
//     },
//   },
//   branchCode: {
//     presence: { allowEmpty: false },
//   },
//   branch: {
//     presence: { allowEmpty: false },
//   },
//   coverDate: {
//     presence: { allowEmpty: false },
//   },
//   coverMonth: {
//     presence: { allowEmpty: false },
//   },
// };

/////--------------Payment Method Implementation-----------------//////////
import { Accordion, Divider } from "@mui/material";
import classNames from "classnames";
import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { DateInput, TextInput, TwoButtons } from "../../../components";
import Summary from "../../../components/Forms/Summary";
import NavigationButtons from "../../../components/NavigationButtons";
import { storeState } from "../../../store/actions/prevState";
import {
  getProductPayment,
  generateCheckout,
  issuePolicy,
  addPolicyBankDetails,
  addPolicyCollectionSchedule,
  approvePolicy,
  sendOTP,
  validateOTP,
  UpdateSteps,
} from "../../../store/actions/products";
import { ATTRIBUTES, RISKS } from "../../../utils/constants";
import useWindowDimensions from "../../../utils/useWindowDimension";
import style from "../Form.module.css";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { validate } from "validate.js";
import ReactSelect from "react-select";
import { Col, Row } from "react-bootstrap";
import { calculateAge60, trackUserEvents } from "../../../utils/utils";

const Step4 = ({ handleNext, handleBack, files, handleFormBack }) => {
  const [attributes, setAttributes] = useState({});
  const [errors, setErrors] = useState({});

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { width } = useWindowDimensions();
  const [isLoading, setIsLoading] = useState(false);
  const premiumAmount = useSelector((state) => state?.products?.premium);
  const policy = useSelector((state) => state?.products?.policy);
  const mainMember = useSelector((state) => state?.products?.mainMember);
  const poliyholderName = mainMember?.firstName;
  const poliyLastName = mainMember?.lastName;
  const [terms, setTerms] = useState({
    aggreement: null,
    terms: false,
    declaration: false,
  });

  const riskAttributes = useSelector(
    (state) => state?.products?.riskAttributes
  );
  const activeStep = useSelector((state) => state.products.activeStep);
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => {
    updateState({});
  }, []);
  const [monthList, setMonthList] = useState();
  const [payDate, setPayDate] = useState();
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("");
  const [errorPayment, setErrorPayment] = useState(false);
  const [isForm, setIsForm] = useState(false);
  const [generateForm, setGenerateForm] = useState("");

  useEffect(() => {
    var optionsList = [
      { value: "01", label: "January" },
      { value: "02", label: "February" },
      { value: "03", label: "March" },
      { value: "04", label: "April" },
      { value: "05", label: "May" },
      { value: "06", label: "June" },
      { value: "07", label: "July" },
      { value: "08", label: "August" },
      { value: "09", label: "September" },
      { value: "10", label: "October" },
      { value: "11", label: "November" },
      { value: "12", label: "December" },
    ];

    // Get current month and calculate 3 months ahead
    const today = new Date();
    const futureMonth = new Date(today.getFullYear(), today.getMonth() + 3, 1);
    console.log("futureMonth-->", futureMonth);
    // Generate options for the dropdown
    let months = [];
    while (today < futureMonth) {
      const currentMonthValue = (today.getMonth() + 1)
        .toString()
        .padStart(2, "0");
      const foundMonth = optionsList.find((x) => x.value === currentMonthValue);

      if (foundMonth) {
        months.push({
          value: currentMonthValue,
          label: foundMonth.label,
        });
      } else {
        console.error(`Month not found for value: ${currentMonthValue}`);
      }

      today.setMonth(today.getMonth() + 1); // Move to the next month
    }

    console.log("Monthss-->", months);
    setMonthList(months);
  }, []);

  const handleChange = async (evt) => {
    let obj = { ...attributes, [evt.target.name]: evt.target.value };
    // setAttributes({
    //   ...attributes,
    //   [evt.target.name]: evt.target.value,
    // });
    setAttributes(obj);
    console.log("obj-->", obj);
    firstPay(obj);
  };

  const firstPay = (obj) => {
    if (obj?.coverDate && obj?.coverMonth) {
      const today = new Date();
      const currentYear = today.getFullYear();
      const selectedMonth = parseInt(obj.coverMonth?.value, 10);

      let yearForSelectedMonth =
        today.getMonth() >= selectedMonth ? currentYear + 1 : currentYear;

      var lastDay = new Date(yearForSelectedMonth, selectedMonth, 0).getDate();
      var lastDebitOrder = `${yearForSelectedMonth}-${obj.coverMonth?.value}-${lastDay}`;

      console.log("lastDebitOrder-->", lastDebitOrder);
      console.log(
        "dateee-->",
        `${yearForSelectedMonth}-${obj.coverMonth?.value}-${obj.coverDate?.value}`
      );
      setPayDate(
        obj.coverDate?.label === "Last Day of the month"
          ? lastDebitOrder
          : `${yearForSelectedMonth}-${obj.coverMonth?.value}-${obj.coverDate?.value}`
      );
    }
  };

  useEffect(() => {
    if (!files || Object.keys(files).length === 0) {
      dispatch(UpdateSteps(activeStep - 1));
      handleFormBack(activeStep - 1);
      forceUpdate();

      return;
    }
  }, []);

  const handlePaymentMethodChange = (event) => {
    setSelectedPaymentMethod(event.target.value);
    setErrorPayment(false);
  };

  const otpResponse = useSelector((state) => state?.products?.otpResponse);

  ////---Payment Initial--(addPolicyBankDetails)--////
  // const handleSubmit = async () => {
  //   const today = new Date();
  //   const currentYear = today.getFullYear();
  //   if (selectedPaymentMethod === "") {
  //     setErrorPayment(true);
  //     return;
  //   } else {
  //     // Perform actions based on the selected payment method
  //     if (selectedPaymentMethod === "Debit Order MP") {
  //       console.log("debitt-->", selectedPaymentMethod);
  //     } else if (selectedPaymentMethod === "MTN") {
  //       console.log("mtnn-->", selectedPaymentMethod);
  //     }
  //   }

  //   ////------Only execute when user selects DebitOrder-----/////////
  //   if (selectedPaymentMethod === "MTN") {
  //     var errors = validate(attributes, schemaOtp);
  //   }

  //   if (selectedPaymentMethod === "Debit Order MP") {
  //   const selectedMonth =
  //     attributes.coverMonth?.value?.charAt(0) === "0"
  //       ? attributes.coverMonth?.value?.replace("0", "")
  //       : attributes.coverMonth?.value;
  //   var lastDay = new Date(currentYear, selectedMonth, 0).getDate();
  //   var lastDebitOrder = `${currentYear}-${attributes.coverMonth?.value}-${lastDay}`;
  //   var errors = validate(attributes, debitSchema);
  //   console.log("payDateee-->", payDate);
  //   console.log("errrorss-->", errors);
  //   // var payDate = '2023-02-08'
  //   if (payDate) {
  //     //---Check date is the previous date or current date------//
  //     const date = new Date(payDate);
  //     const pastDay =
  //       new Date(date.toDateString()) < new Date(new Date().toDateString());
  //     console.log("pastDay-->", pastDay);
  //     if (pastDay === true) {
  //       errors = { ...errors, [`paydate`]: [`Date cannot be in the past.`] };
  //     }

  //     if (pastDay === false) {
  //       var futureDate = new Date(); //Current date
  //       var selectedDate = new Date(payDate); //Selected date by user
  //       var todayDate = new Date(futureDate);
  //       var nextWeek = new Date(
  //         todayDate.getFullYear(),
  //         todayDate.getMonth(),
  //         todayDate.getDate() + 7
  //       );
  //       if (nextWeek > selectedDate) {
  //         console.log("date present within week");
  //         errors = {
  //           ...errors,
  //           [`paydate`]: [
  //             `Date cannot be within the next 7 days from present.`,
  //           ],
  //         };
  //       }
  //     }
  //   }
  // }
  //   if (!files || Object.keys(files).length === 0) {
  //     dispatch(UpdateSteps(activeStep - 1));
  //     handleFormBack(activeStep - 1);

  //     forceUpdate();
  //     return;
  //   }
  //   if (errors) {
  //     console.log("errrorss1111-->", errors);
  //     setErrors(errors);
  //     var err = Object.keys(errors);
  //     const divID = document.getElementById(err[0]);
  //     if (divID) {
  //       window.scrollTo({
  //         top: divID.offsetTop,
  //         behavior: "smooth",
  //       });
  //     }
  //     return;
  //   }
  //   setIsLoading(true);
  //   try {
  //     const res1 = await dispatch(
  //       validateOTP({
  //         key: otpResponse?.key,
  //         password: attributes?.otp,
  //       })
  //     );
  //     if (res1) {
  //       const data = {
  //         externalReference: policy.externalReference,
  //         policySaleReference: policy.policySaleReference,
  //         comment: "Issue Sale from Web App",
  //         policies: [
  //           {
  //             policyReference:
  //               policy.policies?.length && policy.policies[0].policyReference,
  //             bankAccounts: [
  //               {
  //                 externalReference: "CREATE_BANK_100",
  //                 type: attributes.type ?? "test",
  //                 pennyDropStatus: "verified",
  //                 pennyDropMessage: "Verified by XYZ",
  //                 bank: attributes.bank ?? "test",
  //                 accountNumber: attributes.accountNumber ?? "123654",
  //                 accountHolder: attributes.accountHolder ?? "test",
  //                 branch: attributes.branch ?? "test",
  //                 branchCode: attributes.branchCode ?? "123456",
  //                 iban: "IB-200",
  //                 swift: "SW-200",
  //               },
  //             ],
  //           },
  //         ],
  //         webhook: {
  //           webhookUrl:
  //             "https://briisk-i-dev-policies.azurewebsites.net/webhook/saveWebhook",
  //         },
  //       };

  //       // const res = await dispatch(issuePolicy(data));
  //      if (selectedPaymentMethod === "Debit Order MP") {
  //       const data1 = {
  //         type: attributes.type,
  //         pennyDropStatus: "0",
  //         pennyDropMessage: null,
  //         bank: attributes.bank,
  //         accountNumber: attributes.accountNumber,
  //         accountHolder: attributes.accountHolder,
  //         branch: attributes.branch,
  //         iban: "IB-200",
  //         swift: "SW-200",
  //         policyCollectionSchedule: {
  //           // collectionDay : attributes.coverDate.split("-")[2],
  //           collectionDay:
  //             attributes.coverDate?.label === "Last Day of the month"
  //               ? lastDay
  //               : attributes.coverDate?.value,
  //           collectionDuration: 0,
  //           collectionFrequency: "3",
  //           policyFirstCollection: {
  //             firstCollectionAmount: premiumAmount,
  //             FirstCollectionDate:
  //               attributes.coverDate?.label === "Last Day of the month"
  //                 ? lastDebitOrder
  //                 : `${currentYear}-${attributes.coverMonth?.value}-${attributes.coverDate?.value}`,
  //             IsTaxInclusive: true,
  //             FirstCollectionTaxAmount: "0",
  //             Comments:
  //               policy.policies?.length && policy.policies[0].policyReference,
  //           },
  //         },
  //       };

  //     const res = await dispatch(
  //         addPolicyBankDetails(
  //           policy.policySaleReference,
  //           policy.policies[0].policyReference,
  //           data1
  //         )
  //       );
  //       console.log("res->", res);
  //   }
  //       await dispatch(issuePolicy(data));

  //       // const approveddata = {
  //       //   policyReference: policy.policies[0].policyReference,
  //       //   comment : "test for approve"
  //       // }
  //       // await dispatch(approvePolicy(approveddata));
  //       // await dispatch(issuePolicy(data));
  //       //  await dispatch(addPolicyBankDetails(policy.policySaleReference,policy.policies[0].policyReference,data1));
  //       setIsLoading(false);
  //       dispatch(storeState());
  //       navigate("/payment-success");
  //     } else {
  //       toast.error("Please enter valid OTP");
  //       setIsLoading(false);
  //     }
  //   } catch (err) {
  //     toast.error(
  //       attributes?.otp.length !== 4
  //         ? "Please enter valid OTP"
  //         : "Please enter valid OTP"
  //     );
  //     console.log("err--step4--", err);
  //     setIsLoading(false);
  //   }
  // };

  ////-------Payment Integration----(generateCheckOutCall)--------////
  const handleSubmit = async () => {
    const policyType = riskAttributes
      ?.find((y) => y.code === RISKS.UQQ)
      ?.attributes?.find((x) => x.name === ATTRIBUTES.LIVES_COVERED)?.value;
    const coverType = riskAttributes
      ?.find((y) => y.code === RISKS.UQQ)
      ?.attributes?.find((x) => x.name === ATTRIBUTES.COVER_OPTIONS)?.value;
    const coverAmount =
      riskAttributes
        ?.find((y) => y.code === RISKS.UQQ)
        ?.attributes?.find((x) => x.name === ATTRIBUTES.COVER_AMOUNT)?.value ??
      0;
    if (selectedPaymentMethod === "") {
      setErrorPayment(true);
      return;
    } else {
      // Perform actions based on the selected payment method
      if (selectedPaymentMethod === "Debit Order MP") {
        console.log("debitt-->", selectedPaymentMethod);
      } else if (selectedPaymentMethod === "MTN") {
        console.log("mtnn-->", selectedPaymentMethod);
      }
    }
    ////------Only execute when user selects DebitOrder-----/////////
    if (selectedPaymentMethod === "MTN") {
      var errors = validate(attributes, schemaOtp);
    }
    if (selectedPaymentMethod === "Debit Order MP") {
      const today = new Date();
      const currentYear = today.getFullYear();
      // const selectedMonth =
      //   attributes.coverMonth?.value?.charAt(0) === "0"
      //     ? attributes.coverMonth?.value?.replace("0", "")
      //     : attributes.coverMonth?.value;
      const selectedMonth = parseInt(attributes.coverMonth?.value, 10);
      let yearForSelectedMonth =
        today.getMonth() >= selectedMonth ? currentYear + 1 : currentYear;
      var lastDay = new Date(yearForSelectedMonth, selectedMonth, 0).getDate();
      var lastDebitOrder = `${yearForSelectedMonth}-${attributes.coverMonth?.value}-${lastDay}`;
      var errors = validate(attributes, debitSchema);
      console.log("payDateee-->", payDate);
      console.log("errrorss-->", errors);
      console.log("attributess-->", attributes);
      // var payDate = '2023-02-08'
      if (payDate) {
        //---Check date is the previous date or current date------//
        const date = new Date(payDate);
        const pastDay =
          new Date(date.toDateString()) < new Date(new Date().toDateString());
        console.log("pastDay-->", pastDay);
        if (pastDay === true) {
          errors = { ...errors, [`paydate`]: [`Date cannot be in the past.`] };
        }

        if (pastDay === false) {
          var futureDate = new Date(); //Current date
          var selectedDate = new Date(payDate); //Selected date by user
          var todayDate = new Date(futureDate);
          var nextWeek = new Date(
            todayDate.getFullYear(),
            todayDate.getMonth(),
            todayDate.getDate() + 7
          );
          if (nextWeek > selectedDate) {
            console.log("date not present within week");
            errors = {
              ...errors,
              [`paydate`]: [
                `Date cannot be within the next 7 days from present.`,
              ],
            };
          }
        }
      }
    }
    if (!files || Object.keys(files).length === 0) {
      dispatch(UpdateSteps(activeStep - 1));
      handleFormBack(activeStep - 1);

      forceUpdate();
      return;
    }
    // if (selectedPaymentMethod === "Debit Order MP") {
    if (errors) {
      console.log("errrorss1111-->", errors);
      setErrors(errors);
      var err = Object.keys(errors);
      const divID = document.getElementById(err[0]);
      if (divID) {
        window.scrollTo({
          top: divID.offsetTop,
          behavior: "smooth",
        });
      }
      return;
    }
    // }
    ////------Only execute when user selects DebitOrder ends-----/////////
    setIsLoading(true);
    try {
      const res1 = await dispatch(
        validateOTP({
          key: otpResponse?.key,
          password: attributes?.otp,
        })
      );
      if (res1) {
        //-------DebitOrderDate passing------------////
        if (selectedPaymentMethod === "Debit Order MP") {
          const today = new Date();
          const currentYear = today.getFullYear();
          const data1 = {
            // policyCollectionSchedule: {
            // collectionDay : attributes.coverDate.split("-")[2],
            collectionDay:
              attributes.coverDate?.label === "Last Day of the month"
                ? lastDay
                : attributes.coverDate?.value,
            collectionDuration: 0,
            collectionFrequency: "3",
            policyFirstCollection: {
              firstCollectionAmount: premiumAmount,
              FirstCollectionDate:
                attributes.coverDate?.label === "Last Day of the month"
                  ? lastDebitOrder
                  : `${currentYear}-${attributes.coverMonth?.value}-${attributes.coverDate?.value}`,
              IsTaxInclusive: true,
              FirstCollectionTaxAmount: "0",
              Comments:
                policy.policies?.length && policy.policies[0].policyReference,
            },
            // },
          };

          const res = await dispatch(
            addPolicyCollectionSchedule(
              policy.policySaleReference,
              policy.policies[0].policyReference,
              data1
            )
          );
          console.log("res->", res);
        }
        //----eswatini payment methods calls start-----////
        const product_Payment = await dispatch(getProductPayment());
        console.log("productPAymentt-->", product_Payment);
        if (product_Payment) {
          const data = {
            paymentInstanceId: product_Payment.productPayment?.find(
              (x) => x?.providerName === selectedPaymentMethod
            )?.configurationKey, //crct
            email: mainMember?.email,
            companyId: product_Payment.companyInstanceId,
            policyReferenceId: policy && policy?.policies[0]?.policyReference,
            policySaleReference: policy?.policySaleReference,
            amount: premiumAmount,
            currency: "SZL",
            checkoutType: 1,
            shopperSuccessUrl: `${window.location.origin}/payment-success?test="test"&referenceNumber=${policy?.policies[0]?.policyReference}&applicant=${poliyholderName}&premium=${premiumAmount}&saleNumber=${policy?.policySaleReference}&lastName=${poliyLastName}&policyType=${policyType}&coverType=${coverType}&coverAmount=${coverAmount}&paymentMethod=${selectedPaymentMethod}&payDate=${payDate}`,
            shopperFailureUrl: `${window.location.origin}/payment-failure?test="test"&referenceNumber=${policy?.policies[0]?.policyReference}&applicant=${poliyholderName}&premium=${premiumAmount}&saleNumber=${policy?.policySaleReference}&lastName=${poliyLastName}&policyType=${policyType}&coverType=${coverType}&coverAmount=${coverAmount}&paymentMethod=${selectedPaymentMethod}&payDate=${payDate}`,
          };
          const generate_Checkout = await dispatch(generateCheckout(data));
          if (generate_Checkout?.data?.data?.form) {
            console.log("generateCheckout-->", generate_Checkout?.data?.data);
            dispatch(
              storeState({ generateCheckout: generate_Checkout?.data?.data })
            );
            setIsForm(true);
            setGenerateForm(generate_Checkout?.data?.data?.form);
          }
        }
        setIsLoading(false);
        //trackUserEvents
        trackUserEvents("sz_application_payment_details_confirm_cta", {});
        dispatch(storeState());

        //---eswatini payment methods calls ends -----////
        // navigate("/payment-success");
      }
    } catch (err) {
      toast.error(
        attributes?.otp.length !== 4
          ? "Please enter valid OTP"
          : err?.response?.data
      );
      console.log("err--step4--", err, err?.response?.data);
      setIsLoading(false);
    }
  };

  const handleBackTrack = () => {
    //trackUserEvents
    trackUserEvents("sz_complete_your_details_back_cta", {});
    handleBack();
  };

  return (
    <div className="mt-4">
      {width > 770 ? (
        <>
          <div className="d-flex justify-content-center align-items-center flex-column">
            <h3 className="primary-color f-700">Sisekelo Funeral Plan</h3>
            <h2 className="primary-color f-800">Policy Application</h2>
          </div>
          <div
            style={{
              width: "100%",
              height: "1px",
              backgroundColor: "#00000020",
            }}
          ></div>
        </>
      ) : (
        <div
          className={classNames(
            "d-flex justify-content-start align-items-start flex-column",
            style.headerContainer
          )}
        >
          <p className="text-white f-500 m-0 ">Sisekelo Funeral Plan</p>
          <h2 className="primary-color f-800">Policy Application</h2>
        </div>
      )}

      <div className="mt-4 h-100">
        <div className="d-flex justify-content-center flex-column align-items-center mt-5 mb-5">
          {isForm ? (
            isForm && (
              <>
                {console.log("Formm-->", isForm)}
                <Row className={classNames("mt-5")}>
                  <div
                    dangerouslySetInnerHTML={{ __html: generateForm }}
                    style={{ width: "560px", height: "375px" }}
                  />
                </Row>
              </>
            )
          ) : (
            <>
              <Summary
                isSummary={true}
                title="Last Expense Funeral Cover"
                referenceNumber={
                  policy?.policies && policy?.policies[0]?.policyReference
                }
                policyHolder={mainMember?.firstName ?? "NA"}
                policyType={
                  riskAttributes
                    ?.find((y) => y.code === RISKS.UQQ)
                    ?.attributes?.find(
                      (x) => x.name === ATTRIBUTES.LIVES_COVERED
                    )?.value
                }
                coverType={
                  riskAttributes
                    ?.find((y) => y.code === RISKS.UQQ)
                    ?.attributes?.find(
                      (x) => x.name === ATTRIBUTES.COVER_OPTIONS
                    )?.value
                }
                policyHolderSurname={mainMember?.lastName ?? "NA"}
                familyPolicy={
                  riskAttributes
                    ?.find((y) => y.code === RISKS.UQQ)
                    ?.attributes?.find(
                      (x) => x.name === ATTRIBUTES.COVER_AMOUNT
                    )?.value ?? 0
                }
                coverAmount={
                  riskAttributes
                    ?.find((y) => y.code === RISKS.UQQ)
                    ?.attributes?.find(
                      (x) => x.name === ATTRIBUTES.COVER_AMOUNT
                    )?.value ?? 0
                }
                premiumAmount={premiumAmount
                  ?.toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              />
              <Accordion
                defaultExpanded={true}
                style={{ maxWidth: "460px", width: "100%" }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <div className="d-flex flex-row">
                    <p className="f-700 p-0 m-0" style={{ color: "#3C4096" }}>
                      Please enter your payment details here:
                    </p>
                  </div>
                </AccordionSummary>
                <AccordionDetails className="p-0 mt-4">
                  <div
                    style={{
                      border: "1px solid #00000030",
                      padding: 20,
                      borderRadius: 5,
                    }}
                  >
                    <span className="primary-color f-700">
                      Choose payment method{" "}
                    </span>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <label
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                        }}
                      >
                        <input
                          type="checkbox"
                          style={{ transform: "scale(1.4)", marginTop: "3px" }}
                          value="Debit Order MP"
                          checked={selectedPaymentMethod === "Debit Order MP"}
                          onChange={handlePaymentMethodChange}
                        />
                        Debit Order
                      </label>
                    </div>

                    {errorPayment && (
                      <span className="text-danger errors">
                        Choose the payment method
                      </span>
                    )}
                    {selectedPaymentMethod === "Debit Order MP" ? (
                      <>
                        {/* <TextInput
                          label="Account holder name*"
                          placeholder="Full name"
                          value={attributes.accountHolder}
                          name="accountHolder"
                          onChange={handleChange}
                          errors={errors.accountHolder}
                        />
                        <TextInput
                          label="Account number*"
                          placeholder="Your account number"
                          value={attributes.accountNumber}
                          name="accountNumber"
                          onChange={handleChange}
                          errors={errors.accountNumber}
                        />
                        <TextInput
                          title="Account type*"
                          label="Account type*"
                          value={attributes.type}
                          name="type"
                          placeholder=" Your account type"
                          onChange={handleChange}
                          errors={errors.type}
                        />
                        <TextInput
                          title="Bank name*"
                          label="Bank name*"
                          value={attributes.bank}
                          placeholder="Your bank name"
                          name="bank"
                          onChange={handleChange}
                          errors={errors.bank}
                        />
                        <TextInput
                          label="Branch name*"
                          placeholder="Your branch name"
                          value={attributes.branch}
                          name="branch"
                          onChange={handleChange}
                          errors={errors.branch}
                        />
                        <TextInput
                          label="Branch code*"
                          placeholder="Your branch code"
                          value={attributes.branchCode}
                          name="branchCode"
                          onChange={handleChange}
                          errors={errors.branchCode}
                        /> */}
                        <div className="form-group mt-3">
                          <label className="primary-color f-700">
                            Debit order date*
                          </label>
                          <ReactSelect
                            id={`coverDate`}
                            placeholder="Debit order date"
                            value={attributes?.coverDate}
                            options={[
                              { value: "03", label: "3rd" },
                              { value: "20", label: "20th" },
                              { value: "25", label: "25th" },
                              {
                                value: "Last Day of the month",
                                label: "Last Day of the month",
                              },
                            ]}
                            onChange={(e) => {
                              handleChange({
                                target: { name: "coverDate", value: e },
                              });
                            }}
                          />
                          {errors?.["coverDate"] && (
                            <span className="text-danger errors">
                              {errors?.["coverDate"] &&
                                errors?.["coverDate"][0]}
                            </span>
                          )}
                        </div>
                        {/* ///---Month list------/// */}
                        <div className="form-group mt-3">
                          <label className="primary-color f-700">
                            Debit order month*
                          </label>
                          <ReactSelect
                            id={`coverMonth`}
                            placeholder="Debit order month"
                            value={attributes?.coverMonth}
                            options={monthList}
                            onChange={(e) => {
                              handleChange({
                                target: { name: "coverMonth", value: e },
                              });
                            }}
                          />
                          {errors?.["coverMonth"] && (
                            <span className="text-danger errors">
                              {errors?.["coverMonth"] &&
                                errors?.["coverMonth"][0]}
                            </span>
                          )}
                        </div>

                        {/* ///---FirstPaymentDate------/// */}
                        <div className="form-group mt-3">
                          <label className="primary-color f-700">
                            First payment date*
                          </label>
                          <input
                            type="text"
                            disabled={true}
                            placeholder={"FirstPaymentDate"}
                            className="form-control"
                            value={payDate}
                          />
                          {errors?.["paydate"] && (
                            <span className="text-danger errors">
                              {errors?.["paydate"] && errors?.["paydate"][0]}
                            </span>
                          )}
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                    {/* <DateInput 
                    label={'Debit order date'}
                    name={'coverDate'}
                    onChange={handleChange}
                    required={true}
                    value={attributes.coverDate}
                    errors={errors.coverDate}
                    minDate={new Date()}
                /> */}
                    <p className="mt-3">
                      I, the undersigned authorise Liberty to, in terms of the
                      aggreement, deduct the premium for the amount as specified
                      in this form, from this account, including any applicable
                      premium increases I have selected or any increases that
                      Liberty may apply as agreed with me, until the due premium
                      on this policy is paid.
                    </p>
                    <TwoButtons
                      isField={true}
                      half={true}
                      state={terms.aggreement}
                      errors={errors["aggreement"]}
                      onClick={(e) => setTerms({ ...terms, aggreement: e })}
                      options={[
                        {
                          name: "Yes",
                          value: 1,
                        },
                      ]}
                    />
                    <h6 className="mt-3">Declarations</h6>
                    <p>
                      I have read and understood the information for the
                      policyholder & conditions of insurance made available to
                      me/us and agree to accept the insurance to the terms
                      conditions of exclusions and limitations.
                      <br />
                      <br />
                      I confirm that I/am we/are residents of Eswatini
                      <br />
                      <br />I consent to provider, providing me with the
                      information for the policyholder & conditions of
                      insurance, the policy overview and the certificate of
                      insurance via email
                    </p>
                    <TwoButtons
                      isField={true}
                      half={true}
                      state={terms.declaration}
                      errors={errors["declaration"]}
                      onClick={(e) => setTerms({ ...terms, declaration: e })}
                      options={[
                        {
                          name: "Yes",
                          value: 1,
                        },
                      ]}
                    />

                    <Divider />

                    <button
                      className={style.requestOTP}
                      onClick={() => {
                        if (!terms?.declaration || !terms?.aggreement) {
                          return setErrors({
                            ...errors,
                            declaration: !terms?.declaration
                              ? ["Please review the declaration"]
                              : [],
                            aggreement: !terms?.aggreement
                              ? ["Please review the aggreements"]
                              : [],
                          });
                        } else {
                          setErrors({
                            ...errors,
                            declaration: [""],
                            aggreement: [""],
                          });
                        }
                        dispatch(sendOTP())
                          .then((res) => {})
                          .catch((err) =>
                            toast.error(err?.response?.data?.message)
                          );
                      }}
                    >
                      Request OTP
                    </button>
                    <TextInput
                      id="otp"
                      label=""
                      name="otp"
                      placeholder={"Enter your OTP"}
                      value={attributes?.otp}
                      errors={errors?.otp}
                      onChange={(e) =>
                        setAttributes({ ...attributes, otp: e.target.value })
                      }
                    />
                    <span style={{ fontSize: "14px" }}>
                      By entering the OTP, you accept the T&C’s and debit order
                      mandate
                    </span>
                    <div className=" mt-3 d-flex justify-content-between flex-row align-items-start">
                      <TwoButtons
                        isField={true}
                        half={true}
                        onClick={(e) => setTerms({ ...terms, terms: e })}
                        state={terms.terms}
                        errors={errors?.["terms"]}
                        options={[
                          {
                            name: "Yes",
                            value: 1,
                          },
                        ]}
                      />
                      <p>
                        By ticking this box. I accept that the premium for this
                        policy will be collected monthly for payment. I further
                        declare that I have read and understood the Payment
                        Terms & Conditions.
                      </p>
                    </div>
                    <Divider />
                    <div style={{ height: "20px" }} />
                    <button
                      className={style.confirm_purchase}
                      onClick={() => {
                        if (
                          !terms?.terms ||
                          !terms?.aggreement ||
                          !terms?.declaration
                        ) {
                          setErrors({
                            ...errors,
                            terms: !terms?.terms
                              ? ["Please review the terms."]
                              : [],
                            declaration: !terms?.declaration
                              ? ["Please review the declaration"]
                              : [],
                            aggreement: !terms?.aggreement
                              ? ["Please review the aggreements"]
                              : [],
                          });
                        } else {
                          setErrors({
                            ...errors,
                            terms: [],
                            declaration: [],
                            aggreement: [],
                          });
                          handleSubmit();
                        }
                      }}
                    >
                      {isLoading ? (
                        <div class="lds-ring">
                          <div></div>
                          <div></div>
                          <div></div>
                        </div>
                      ) : (
                        "Confirm Purchase"
                      )}
                    </button>
                  </div>
                </AccordionDetails>
              </Accordion>
            </>
          )}
        </div>
      </div>

      <div style={{ height: "20px" }} />

      <div className={style.step_1_price_summary}>
        <div className={style.step_1_price_summary_sub}>
          <h5 className="text-white f-700">Total Monthly Premium</h5>
          <h5 className="text-white f-700">
            SZL{" "}
            {premiumAmount
              ? premiumAmount?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              : 0 ?? 0}
          </h5>
        </div>
      </div>

      <NavigationButtons
        back={handleBackTrack}
        payment={true}
        isBackDisabled={isLoading}
        next={() => {
          if (!terms) {
            return toast.error("Please confirm terms & conditions");
          }
          dispatch(storeState());
          navigate("/payment-success");
        }}
      />
      {isLoading && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            display: "flex",
            backgroundColor: "rgba(0,0,0,0.1)",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          {/* <Loader /> */}
        </div>
      )}
    </div>
  );
};

export default Step4;

const debitSchema = {
  // accountHolder: {
  //   presence: { allowEmpty: false },
  //   format: {
  //     pattern: "[a-zA-Z].+",
  //     flags: "g",
  //     message: "can only contain alphabets",
  //   },
  // },
  // accountNumber: {
  //   presence: { allowEmpty: false },
  //   format: {
  //     pattern: "^[0-9]+$",
  //     message: "can only contain number",
  //   },
  // },
  // type: {
  //   presence: { allowEmpty: false },
  //   format: {
  //     pattern: "[a-zA-Z].+",
  //     flags: "g",
  //     message: "can only contain alphabets",
  //   },
  // },
  // bank: {
  //   presence: { allowEmpty: false },
  //   format: {
  //     pattern: "[a-zA-Z].+",
  //     flags: "g",
  //     message: "can only contain alphabets",
  //   },
  // },
  // branchCode: {
  //   presence: { allowEmpty: false },
  // },
  // branch: {
  //   presence: { allowEmpty: false },
  // },
  coverDate: {
    presence: { allowEmpty: false },
  },
  coverMonth: {
    presence: { allowEmpty: false },
  },
  otp: {
    presence: {
      allowEmpty: false,
      message: "^OTP can't be blank",
    },
  },
};

const schemaOtp = {
  otp: {
    presence: {
      allowEmpty: false,
      message: "^OTP can't be blank",
    },
  },
};
