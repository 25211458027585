/* eslint-disable jsx-a11y/anchor-is-valid */
// default imports for react project
import React, { useState } from "react";

// import default style component from react-bootstrap package
import { Col, Container, Row, Button } from "react-bootstrap";

// Import image from assets folder
import {
  comingSoon,
  main,
  FullMain,
  liberty,
  dateIcon,
} from "../../assets/images";

//   import custom component
import BodyButton from "../../components/Buttons/BodyButton";
import Card2 from "../../components/Card/Card2";
import { DateInput } from "../../components";

// import util to get current window dimensions
import useWindowDimensions from "../../utils/useWindowDimension";
// Import image from assets folder
import {
  ProductImage,
} from "../../assets/images";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import brochure from "../../assets/documents/Mosako Funeral Cover Flyer.pdf";
import mybrochure from "../../assets/documents/LETSGO INSURE BROCHURE6.pdf";
import {
  getProductRisks,
  getProductRisksAttributes,
  updatedAttributes,
  getProductTypeDetails,
  getProductAttachments,
  updateCustomAttributes,
} from "../../store/actions/products";
import { getAgentOnboarding, login } from "../../store/actions/auth";
import { ATTRIBUTES, RISKS, CONFIG } from "../../utils/constants";
import { calculator } from "../../components/calculator";
import Loader from "../../components/Loader";
import moment from "moment";
import { toast } from "react-toastify";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { parseQuery, setParam, trackUserEvents } from "../../utils/utils";

// React functional component starts here..
function Products({ isHomeLoading }) {
  // scroll window to top of page on navigation
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Get width from util
  const { width } = useWindowDimensions();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    // dispatch(clearState());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);
  const history = useNavigate();
  const currentToken = useSelector((state) => state.auth.currentUserToken);

  const agentCode = useSelector((state) => state.auth.agentCode);

  const [errors, setErrors] = useState({});

  const agentOnBoarding = useSelector((state) => state.auth.agentOnboarding);

  const [isLoading, setIsLoading] = useState(false);

  const [homeLoading, setHomeLoading] = useState(false);

  const state = useSelector((state) => state.products.riskAttributes);
  //const countDown = useSelector((state) => state?.products?.countDown.count);

  // Risk from the Product
  const risks = useSelector((state) =>
    state.products?.risks?.risks?.sort((x, y) =>
      x.sequence > y.sequence ? 1 : -1
    )
  );

  // Risk attributes from the Product i.e. BUD, CEU, ICU, etc.
  const risksAttributes = useSelector((state) =>
    state.products?.riskAttributes?.sort((x, y) =>
      x.sequence > y.sequence ? 1 : -1
    )
  );

  useEffect(() => {
    dispatch({
      type: "CURRENT_USER_TOKEN",
      payload: parseQuery(location.search).token ?? currentToken,
    });
  }, []);

  useEffect(() => {
    setParam(history, currentToken);
  }, [currentToken]);

  const reduxState = useSelector((state) => state);
  const riskAttributes = useSelector(
    (state) =>
      state?.products?.riskAttributes?.find((x) => x.code === "QUQ")?.attributes
  );

  const [isCounter, setIsCounter] = useState(false);
  let timer = null;

  return (
    <div style={{ backgroundColor: "#E5E5F5" }}>
      <Container>
        <Row>
          <div
            style={{
              color: "#2f2e80",
              fontSize: "32px",
              marginTop: "25px",
              fontWeight: "700",
            }}
          >
            {" "}
            Products{" "}
          </div>
        </Row>{" "}
        <Row>
          <p
            style={{
              color: "#2f2e80",
              fontSize: "22px",
              marginTop: "25px",
              fontWeight: "700",
            }}
          >
            1. Sisekelo Funeral Plan
          </p>
        </Row>

        <Row>
          <p
            style={{
              color: "#2f2e80",
              fontSize: "20px",
              marginTop: "25px",
              fontWeight: "bolder",
            }}
          >
          BENEFITS
          </p>
        </Row>


        <Row>
          <p
            style={{
              color: "#2f2e80",
            }}
          >
           Cover will be provided on death of the policyholder
or a member of his family according to the following
benefit scales: 
          </p>
          <br/>
          <p
            style={{
              color: "green",
              fontWeight: "bolder",
            }}
          >
MAIN MEMBER : 100%
            </p>
<br/>
<p
            style={{
              color: "green",
              fontWeight: "bolder",
            }}
          >
SPOUSE : 100%
            </p>

<br/>
<p
            style={{
              color: "green",
              fontWeight: "bolder",
            }}
          >
CHILD age 14-21 : 100%
            </p>
            <br/>
            <p
            style={{
              color: "green",
              fontWeight: "bolder",
            }}
          >
CHILD age 6-13 : 50%
            </p>
<br/>
<p
            style={{
              color: "green",
              fontWeight: "bolder",
            }}
          >
CHILD under 5 : 25%
            </p>


        </Row>



        <Row>
        <p
            style={{
              color: "black",
            }}
          >
        The main member may chose a sum assured of
        E5,000, E10,000, E20,000 or E30,000
        </p>
        </Row>

        <Row>
          <p
            style={{
              color: "#2f2e80",
              fontSize: "20px",
              marginTop: "25px",
              fontWeight: "bolder",
            }}
          >
          KEY BENEFIT
          </p>
        </Row>

         <Row>
          <p
            style={{
              color: "#000",
              fontSize: "15px",
              marginTop: "25px",
              fontWeight: "400",
            }}
          >
          This Plan provides a lump sum benefit on death of
the Insured Person to aid in covering funeral related
expenses
          </p>
          <br/>
          <ul>

            <li>Cover for accidental death will start
on the receipt of a fully completed
application form and supporting
document.</li>
<li>Cover for natural deaths will
commence on receipt
of first premium.</li>
          </ul>
        </Row>  
        
        <Row>
          <p
            style={{
              color: "#2f2e80",
              fontSize: "20px",
              marginTop: "25px",
              fontWeight: "bolder",
            }}
          >
          ELIGIBILITY
          </p>
        </Row>

        <Row>
          <p
            style={{
              color: "#000",
              fontSize: "15px",
              marginTop: "25px",
              fontWeight: "400",
            }}
          >
       The benefit can be taken up on a voluntary basis. The
product can cover a main member only or a family.
A family can consist of a maximum of one main
member, maximum one spouse and a maximum of
6 children.
          </p>

          </Row>




          <Row>
          <p
            style={{
              color: "#2f2e80",
              fontSize: "20px",
              marginTop: "25px",
              fontWeight: "bolder",
            }}
          >
          SUPPORTING DOCUMENTS REQUIRED
          </p>
        </Row>



        <Row>
         
          <br/>
          <ul>
          <li>Completed application
          (Online)</li>
            <li>Certified copy of
 identity document/
valid passport</li>
<li>Proof of valid Bank Account
Details in policyholder’s name
(for debit order payments)</li>

<li>Proof of residence
(must not be older than
three (3) months)</li>

<li>Proof of source of
funds/income/wealth</li>

          </ul>
        </Row>  
        

        <Row>
          <p
            style={{
              color: "#2f2e80",
              fontSize: "20px",
              marginTop: "25px",
              fontWeight: "bolder",
            }}
          >
          PROCESS OF APPLICATION
          </p>
        </Row>
<Row>
  <ol>
        <li>Download & Register on LetsGo App</li>

        <li>Login</li>
        <li>LetsGo Insure</li>
        <li>Family Protection</li>
        <li>Family Protection</li>
       <li> Buy Cover of Your Choice from Sisekelo
Funeral Plan</li>
          </ol>
        </Row>  


        <Row>
          <p
            style={{
              color: "#2f2e80",
              fontSize: "20px",
              marginTop: "25px",
              fontWeight: "bolder",
            }}
          >
       
    2. OPTIONAL BENEFITS ON FUNERAL PLAN
          </p>
        </Row>
        <img
                className="main__img"
                src={ProductImage}
                alt="main"
                style={{ width: "75%", paddingTop: "0%", height: "auto" }}
              />



        <Row>
           <div style={{ color: "#2f2e80" }}>
            {" "}
            Siseko Funeral Cover Flyer:{"   "}&nbsp;
            <a
              style={{ color: "#2f2e80", fontWeight: "bold" }}
              rel="noreferrer"
              href={mybrochure}
              target="_blank"
            >
              [ Download Cover ]
            </a>
          </div>
        </Row>
        <div style={{ height: "200px" }}></div>
      </Container>
    </div>
  );
}

export default Products;
