/* eslint-disable jsx-a11y/anchor-is-valid */
// default imports for react project
import React, { useState } from "react";
import Accordion from "../../components/Accordion";
// import default style component from react-bootstrap package
import { Col, Container, Row, Button } from "react-bootstrap";

// Import image from assets folder
import { comingSoon } from "../../assets/images";

//import util to get current window dimensions
import useWindowDimensions from "../../utils/useWindowDimension";
import { travelSuperiorPlan } from "./data";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import "react-circular-progressbar/dist/styles.css";
import { parseQuery, setParam, trackUserEvents } from "../../utils/utils";
// React functional component starts here..
function Faqs({ isHomeLoading }) {
  // scroll window to top of page on navigation
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Get width from util
  const { width } = useWindowDimensions();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    // dispatch(clearState());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);
  const history = useNavigate();
  const currentToken = useSelector((state) => state.auth.currentUserToken);

  useEffect(() => {
    dispatch({
      type: "CURRENT_USER_TOKEN",
      payload: parseQuery(location.search).token ?? currentToken,
    });
  }, []);

  useEffect(() => {
    setParam(history, currentToken);
  }, [currentToken]);

  return (
    <div style={{ backgroundColor: "#E5E5F5" }}>
      <Container>
        <Row>
          <div
            style={{
              color: "#2f2e80",
              fontSize: "32px",
              marginTop: "25px",
              fontWeight: "700",
            }}
          >
            {" "}
            Frequently Asked Questions{" "}
          </div>
        </Row>
        <Row>
          {travelSuperiorPlan?.map((plan, i) => (
            <Accordion key={i} title={plan?.title} style={{ color: "#2f2e80" }}>
              <div style={{ color: "#2f2e80" }}>
                {plan?.answer}
                <ul>
                  {plan?.subAnswer?.map((ans, j) => (
                    <li key={j}>{ans}</li>
                  ))}
                </ul>
              </div>
            </Accordion>
          ))}
        </Row>
        <div style={{ height: "200px" }}></div>
      </Container>
    </div>
  );
}

export default Faqs;
