/* eslint-disable jsx-a11y/anchor-is-valid */
// default imports for react project
import React, { useState } from "react";

// import default style component from react-bootstrap package
import { Col, Container, Row, Button } from "react-bootstrap";

// Import image from assets folder
import {
  comingSoon,
  main,
  FullMain,
  liberty,
  dateIcon,
} from "../../assets/images";

//   import custom component
import BodyButton from "../../components/Buttons/BodyButton";
import Card2 from "../../components/Card/Card2";
import { DateInput } from "../../components";

// import util to get current window dimensions
import useWindowDimensions from "../../utils/useWindowDimension";

import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import brochure from "../../assets/documents/Mosako Funeral Cover Flyer.pdf";
import {
  getProductRisks,
  getProductRisksAttributes,
  updatedAttributes,
  getProductTypeDetails,
  getProductAttachments,
  updateCustomAttributes,
} from "../../store/actions/products";
import { getAgentOnboarding, login } from "../../store/actions/auth";
import { ATTRIBUTES, RISKS, CONFIG } from "../../utils/constants";
import { calculator } from "../../components/calculator";
import Loader from "../../components/Loader";
import moment from "moment";
import { toast } from "react-toastify";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { parseQuery, setParam, trackUserEvents } from "../../utils/utils";

// React functional component starts here..
function PrivacyPolicy({ isHomeLoading }) {
  // scroll window to top of page on navigation
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Get width from util
  const { width } = useWindowDimensions();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    // dispatch(clearState());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);
  const history = useNavigate();
  const currentToken = useSelector((state) => state.auth.currentUserToken);

  const agentCode = useSelector((state) => state.auth.agentCode);

  const [errors, setErrors] = useState({});

  const agentOnBoarding = useSelector((state) => state.auth.agentOnboarding);

  const [isLoading, setIsLoading] = useState(false);

  const [homeLoading, setHomeLoading] = useState(false);

  const state = useSelector((state) => state.products.riskAttributes);
  //const countDown = useSelector((state) => state?.products?.countDown.count);

  // Risk from the Product
  const risks = useSelector((state) =>
    state.products?.risks?.risks?.sort((x, y) =>
      x.sequence > y.sequence ? 1 : -1
    )
  );

  // Risk attributes from the Product i.e. BUD, CEU, ICU, etc.
  const risksAttributes = useSelector((state) =>
    state.products?.riskAttributes?.sort((x, y) =>
      x.sequence > y.sequence ? 1 : -1
    )
  );

  useEffect(() => {
    dispatch({
      type: "CURRENT_USER_TOKEN",
      payload: parseQuery(location.search).token ?? currentToken,
    });
  }, []);

  useEffect(() => {
    setParam(history, currentToken);
  }, [currentToken]);

  const reduxState = useSelector((state) => state);
  const riskAttributes = useSelector(
    (state) =>
      state?.products?.riskAttributes?.find((x) => x.code === "QUQ")?.attributes
  );

  const [isCounter, setIsCounter] = useState(false);
  let timer = null;

  return (
    <div style={{ backgroundColor: "#E5E5F5" }}>
      <Container>
        <Row>
          <div
            style={{
              color: "#2f2e80",
              fontSize: "32px",
              marginTop: "25px",
              fontWeight: "700",
            }}
          >
            {" "}
            PrivacyPolicy{" "}
          </div>
        </Row>{" "}
        <Row>
          <p
            style={{
              color: "#2f2e80",
              fontSize: "22px",
              marginTop: "25px",
              fontWeight: "700",
            }}
          >
            Letshego Privacy Policy
          </p>
        </Row>
        <Row>
          <p
            style={{
              color: "#2f2e80",
            }}
          >
            At Letshego Holdings Limited, we care about your privacy. We
            understand that you are the ultimate owner of your Personal
            Information. The purpose of this privacy policy is to be transparent
            about how we treat the information we may have about you. We also
            wish to inform our CUSTOMERS that Letshego Holdings Limited TAKES
            STRICT measures as per this privacy policy to ensure that their data
            remains confidential. We use the most state-of-the-art security
            technology and systems to protect YOUR data against fraud,
            unauthorised access and alteration and loss of information.
          </p>
        </Row>{" "}
        <Row>
          <p
            style={{
              color: "#2f2e80",
              fontSize: "22px",
              marginTop: "25px",
              fontWeight: "700",
            }}
          >
            Privacy Policy Coverage Range (Scope)
          </p>
        </Row>
        <Row>
          <p
            style={{
              color: "#2f2e80",
            }}
          >
            Letshego Holdings Limited collects the minimum amount of Personal
            Information possible to ensure that we can conduct business with you
            legally, and in a manner that protects all parties.
          </p>
        </Row>{" "}
        <Row>
          <p
            style={{
              color: "#2f2e80",
            }}
          >
            Letshego Holdings Limited has a footprint in 11 countries in sub
            Saharan Africa and, as such, needs to be compliant in each specific
            jurisdiction. Our Privacy Policy incorporates all the territorial
            laws in the countries where we operate. Should the policy contravene
            any law or regulation, the territorial laws and regulations prevail,
            and Letshego will ensure compliance. This policy covers all services
            that Letshego Holdings Limited delivers via any channel in all
            Letshego Holdings Limited subsidiaries across Africa.
          </p>
        </Row>{" "}
        <Row>
          <p
            style={{
              color: "#2f2e80",
            }}
          >
            If you think that Letshego contravened any law or regulation, please
            use the contact us feature on the Letshego website
            (https://www.letshego.com ) or contact your local Letshego Call
            Centre.
          </p>
        </Row>{" "}
        <Row>
          <p
            style={{
              color: "#2f2e80",
              fontSize: "22px",
              marginTop: "25px",
              fontWeight: "700",
            }}
          >
            Third-Party Disclosure
          </p>
        </Row>
        <Row>
          <p
            style={{
              color: "#2f2e80",
            }}
          >
            Letshego Holdings Limited policy does not apply where you share
            information with any third party arising from doing business with
            Letshego. For example, your bank may contact you because you want a
            Letshego loan. Should you provide them with information and sell or
            abuse your Personal Information, Letshego Holdings Limited cannot be
            held accountable. Letshego cannot be held liable for any contract,
            whether implicit or explicit, that you may have with another party,
            including guarantees and warranties. Always make sure that you read
            and understand any third party’s privacy policy and their terms and
            conditions. Not all companies will provide the same high level of
            protection that Letshego Holdings Limited does!
          </p>
        </Row>{" "}
        <Row>
          <p
            style={{
              color: "#2f2e80",
              fontSize: "22px",
              marginTop: "25px",
              fontWeight: "700",
            }}
          >
            Your Personal Information
          </p>
        </Row>
        <Row>
          <p
            style={{
              color: "#2f2e80",
            }}
          >
            Any information that can be used to identify you that we may have on
            record (including log files) is your Personal Information. Your
            Personal Information includes information that you supplied when
            completing forms and facsimiles, photographs, scans, and other
            reproductions of your information documents. Personal information
            includes your Name, Identity Information, Passwords, and Contact
            Details. Your Personal Information also includes quasi-identifiers
            that can be used to group you in any way like your age, race, sex,
            medical information, where you shop and where you invest.
          </p>
        </Row>{" "}
        <Row>
          <p
            style={{
              color: "#2f2e80",
            }}
          >
            At Letshego Holdings Limited, we will not collect any information
            that is not required to do business with you. We will never gather
            data that is illegal to collect, like medical and genetic
            information, nor data that has no bearing on business like sexual or
            political preferences. Letshego will not store any data regarding
            minors. Letshego also records information about the devices you use
            to access our systems to prevent fraud.
          </p>
        </Row>{" "}
        <Row>
          <p
            style={{
              color: "#2f2e80",
            }}
          >
            It does not matter whether you provided the information
            electronically, verbally or physically. All your Personal
            Information is classified as confidential and treated with the same
            respect.
          </p>
        </Row>{" "}
        <Row>
          <p
            style={{
              color: "#2f2e80",
            }}
          >
            All information that third parties like the government, your company
            or bank may provide about you or on your behalf is treated as
            strictly confidential for Letshego Holdings Limited.
          </p>
        </Row>{" "}
        <Row>
          <p
            style={{
              color: "#2f2e80",
            }}
          >
            In most countries, the data protection act will allow the client to
            withdraw consent to store and use information under some
            circumstances. Letshego Holdings Limited endeavours to ensure that
            your Personal Information is at your fingertips securely via our
            electronic platforms and our sales agents when you may want to
            review the information.
          </p>
        </Row>{" "}
        <Row>
          <p
            style={{
              color: "#2f2e80",
              fontSize: "22px",
              marginTop: "25px",
              fontWeight: "700",
            }}
          >
            Why do we need to collect personal information?
          </p>
        </Row>
        <Row>
          <div
            style={{
              color: "#2f2e80",
            }}
          >
            <p
              style={{
                color: "#2f2e80",
              }}
            >
              We will not collect excessive personal data and your data will be
              used for specified, explicit, and legitimate purposes and in line
              with applicable laws. We will not ask our customers, or any third
              party, for any of your information unless it is to achieve the
              following:
            </p>
            <ul>
              <li>
                We need your personal information to serve you in your current
                product needs and related services. We also need your Personal
                Information to fulfil contractual requirements to help you and
                communicate with you (either directly or via a third party)
                using any available medium, e.g. email, electronic messaging, or
                voice calls.
              </li>
              <li>
                We need your personal information to provide you with
                information regarding new offers and services, competitions,
                great deals or promotions. Letshego Holdings Limited will always
                allow you the ability to opt out of marketing communications
                whenever you wish.
              </li>
              <li>
                Letshego Holdings Limited will need personal information to
                improve our products, services, and statistics.
              </li>
              <li>
                To manage our relationship with you, we need to use some of your
                personal information.
              </li>
              <li>
                Letshego Holdings Limited must comply with Laws and Regulations.
              </li>
              <li>
                Letshego may need to provide Information to Credit Agencies.
              </li>
              <li>
                By law, Letshego must keep your records up to date and will
                require your latest information to do so.
              </li>
              <li>
                Letshego Holding Limited will need your personal information to
                prevent or identify potential fraud.
              </li>
            </ul>
          </div>
        </Row>{" "}
        <Row>
          <p
            style={{
              color: "#2f2e80",
            }}
          >
            Should we ever share your information with a third party, we will
            ensure they are made aware of our policy and agree to it in writing.
            Your data will not be disclosed, made available, or used for other
            purposes without your consent or otherwise authorised by law.
          </p>
        </Row>{" "}
        <Row>
          <p
            style={{
              color: "#2f2e80",
            }}
          >
            Letshego limits third-party communications to use the absolute
            minimum personal information to allow the business to happen. All
            this type of communication gets encrypted to ensure that your
            information is not inadvertently disclosed. Letshego does not sell
            or rent out any personal information to third parties.
          </p>
        </Row>{" "}
        <Row>
          <p
            style={{
              color: "#2f2e80",
            }}
          >
            Should you contact us telephonically, we will need you to correctly
            provide a subset of the information to ensure that we do not share
            your information with malicious actors. If we contact you, we may
            request the same to ensure that you answer the phone. Once we have
            established that you are the owner of the specific account, we may
            be able to share some details with you, like your forgotten account
            number, for example.
          </p>
        </Row>{" "}
        <Row>
          <p
            style={{
              color: "#2f2e80",
            }}
          >
            <span
              style={{
                color: "#2f2e80",
                fontWeight: "600",
              }}
            >
              Letshego will never contact you in person, telephonically or via
              any other means to request any authentication information, e.g.
              any password or passphrase or pins.
            </span>
            {/* <span
              style={{
                color: "#2f2e80",
              }}
            > */}
            Never give anybody including anybody from Letshego your PIN,
            password, or passphrase.
            {/* </span> */}
          </p>
        </Row>{" "}
        <Row>
          <p
            style={{
              color: "#2f2e80",
            }}
          >
            Also, remember that no Letshego Agent will ever request to come to
            your home to conduct business.
          </p>
        </Row>{" "}
        <Row>
          <p
            style={{
              color: "#2f2e80",
              fontSize: "22px",
              marginTop: "25px",
              fontWeight: "700",
            }}
          >
            Methods we use to collect Personal Information
          </p>
        </Row>
        <Row>
          <div
            style={{
              color: "#2f2e80",
            }}
          >
            <p
              style={{
                color: "#2f2e80",
              }}
            >
              Letshego Holdings Limited collates your personal information into
              a database to simplify the management and security of your
              Personal Data. You usually provide your Personal Information in
              the course of conducting business with Letshego to enable us to
              provide service via one of and could be gathered:
            </p>
            <ul>
              <li>
                By a Sales Agent using forms, brochures, or other material at a
                physical service point.
              </li>
              <li>
                Call Centre agents need to verify that they are talking to real
                customers need to compare information on record with what you
                can supply verbally.
              </li>
              <li>
                Letshego Holdings Limited records calls in our Call Centers, and
                these recordings often contain some personal information.
              </li>
              <li>
                Via online forms using any electronic channel including
                Websites, Applications, USSD and Whatsapp.
              </li>
              <li>
                When using Letshego free guest wifi, we may also record some
                personal information.
              </li>
              <li>
                Letshego monitors email to prevent phishing and other types of
                attacks, and these systems record some personal information.
              </li>
              <li>
                When you send requests or complaints to Letshego Holdings
                Limited.
              </li>
              <li>
                When you update the information, Letshego has for you by
                contacting a Sales Agent or using any of our digital channels to
                do the update.
              </li>
              <li>
                Third parties like banks and your place of work may need to
                provide us with some of your information to ensure else it will
                be impossible to do transactions.
              </li>
            </ul>
          </div>
        </Row>{" "}
        <Row>
          <p
            style={{
              color: "#2f2e80",
            }}
          >
            Letshego stores and transmits data in compliance with local laws and
            regulations and ensures that data is always encrypted. Letshego
            Holdings Limited will not buy or obtain Personal Information
            illegally.
          </p>
        </Row>{" "}
        <Row>
          <p
            style={{
              color: "#2f2e80",
            }}
          >
            Suppose you need to understand better why we collect certain
            Personal Information or what information we collect. In that case,
            you could obtain more information from your Sales Agent, a Call
            Centre Agent or by using the “Contact Us”-facility on our website:
            {" "}<a
              href="https://www.letshego.com/contact-us"
              target="_blank"
              style={{ color: "#007bff" }}
            >
              https://www.letshego.com/contact-us
            </a>
          </p>
        </Row>{" "}
        <Row>
          <p
            style={{
              color: "#2f2e80",
              fontSize: "22px",
              marginTop: "25px",
              fontWeight: "700",
            }}
          >
            Change of Personal Information
          </p>
        </Row>
        <Row>
          <p
            style={{
              color: "#2f2e80",
            }}
          >
            You can request Letshego to view the data we have for you at any
            time, and Letshego will provide it in readable format within a
            reasonable time.
          </p>
        </Row>{" "}
        <Row>
          <div
            style={{
              color: "#2f2e80",
            }}
          >
            <p
              style={{
                color: "#2f2e80",
              }}
            >
              It is law in most territories to keep Personal Information up to
              date. Should any of your Personal Information change, e.g. your
              address or telephone number, please contact Letshego Holdings
              Limited immediately to update our files. You could:
            </p>
            <ul>
              <li>
                Visit your closest Letshego branch to update your information or
              </li>
              <li>
                You could use any of our electronic channels to update the
                information
              </li>
              <li>
                You also have the right to be deleted from our records when your
                record is not required under law in your territory of
                jurisdiction. Letshego will not store the data longer than
                required by law.
              </li>
            </ul>
          </div>
        </Row>{" "}
        <Row>
          <p
            style={{
              color: "#2f2e80",
              fontSize: "22px",
              marginTop: "25px",
              fontWeight: "700",
            }}
          >
            Log Files
          </p>
        </Row>
        <Row>
          <p
            style={{
              color: "#2f2e80",
            }}
          >
            All Applications and Web services log information for statistical
            purposes and ensure that transactions are correct and
            non-fraudulent. The principle Letshego Holdings Limited follows is
            to exclude Personal Information or to delete and mask wherever
            personal information is logged unless it can assist in fraud
            prevention. Log files are typically used by the Letshego security
            and fraud teams and are meticulously protected to ensure security.
          </p>
        </Row>{" "}
        <Row>
          <p
            style={{
              color: "#2f2e80",
              fontSize: "22px",
              marginTop: "25px",
              fontWeight: "700",
            }}
          >
            Information Access on the Devices
          </p>
        </Row>
        <Row>
          <p
            style={{
              color: "#2f2e80",
            }}
          >
            Letshego may access information on your device solely for the
            transaction. The information will not be stored as part of Letshego
            data and/or used for marketing purposes. We will always request your
            consent before accessing data from other applications and inform you
            of how we intend to use it:
          </p>
        </Row>{" "}
        <Row>
          <p
            style={{
              color: "#2f2e80",
            }}
          >
            Letshego will request to access the contacts on your device to
            enable you to transfer money to a Letshego accounts to ensure that
            the correct person will receive the transfer.
          </p>
        </Row>{" "}
        <Row>
          <p
            style={{
              color: "#2f2e80",
              fontSize: "22px",
              marginTop: "25px",
              fontWeight: "700",
            }}
          >
            Cookies
          </p>
        </Row>
        <Row>
          <p
            style={{
              color: "#2f2e80",
            }}
          >
            Cookies are information that any Letshego Website or Application
            sends for storage on your local hard drive for storage. In this way,
            the next time you use any of our services, we will know who you are,
            that you have visited the website and used our Internet Services
            before.
          </p>
        </Row>{" "}
        <Row>
          <p
            style={{
              color: "#2f2e80",
            }}
          >
            Letshego Holdings Limited ensures that it complies with laws and
            regulations in all the territories it trades when it comes to
            cookies. We will always place a Cookie Notice on all sites where we
            host cookies to comply with territorial laws and regulations.
          </p>
        </Row>{" "}
        <Row>
          <p
            style={{
              color: "#2f2e80",
            }}
          >
            Rooting (Jailbreaking) are applications that breaks your mobile
            device security. No Letshego Application will run on Rooted
            (Jailbreaked) devices as it potentially exposes our customers to
            third parties that may want to steal your personal information.
          </p>
        </Row>{" "}
        <Row>
          <p
            style={{
              color: "#2f2e80",
              fontSize: "22px",
              marginTop: "25px",
              fontWeight: "700",
            }}
          >
            Purpose for Cookies
          </p>
        </Row>
        <Row>
          <p
            style={{
              color: "#2f2e80",
            }}
          >
            Letshego Holdings Limited uses cookies to ensure that hackers don’t
            gain access to your information (session management and device
            identification) and for analytical, routing and classification
            purposes.
          </p>
        </Row>{" "}
        <Row>
          <p
            style={{
              color: "#2f2e80",
              fontSize: "22px",
              marginTop: "25px",
              fontWeight: "700",
            }}
          >
            Disablement of Cookies
          </p>
        </Row>
        <Row>
          <p
            style={{
              color: "#2f2e80",
            }}
          >
            Clearing and disabling cookies limits the functionality of Letshego
            Websites and Applications. They could cause erratic behaviour like
            re-forcing logon, amongst other issues.
          </p>
        </Row>{" "}
        <Row>
          <p
            style={{
              color: "#2f2e80",
            }}
          >
            Should you feel uncomfortable with Cookies, you may limit the
            collection of your information by disabling cookies on your browser.
            There is also a way to ensure that you give your permission each
            time a site attempts to set a cookie.
          </p>
        </Row>{" "}
        <Row>
          <p
            style={{
              color: "#2f2e80",
            }}
          >
            Letshego Holdings Applications do rely on cookies to function
            correctly. You will not have full functionality if you do not accept
            the cookies we try to set.
          </p>
        </Row>{" "}
        <Row>
          <p
            style={{
              color: "#2f2e80",
              fontSize: "22px",
              marginTop: "25px",
              fontWeight: "700",
            }}
          >
            Types of Cookies
          </p>
        </Row>
        <Row>
          <p
            style={{
              color: "#2f2e80",
            }}
          >
            Letshego Holdings Limited uses both session cookies and persistent
            cookies. Session cookies help the application understand what you
            are currently busy with. These cookies are short-lived and get
            deleted when you log out. Persistent cookies are stored over more
            extended periods and will expire. These cookies will be set up again
            each time you visit our site. You can delete cookies at any time.
          </p>
        </Row>{" "}
        <Row>
          <p
            style={{
              color: "#2f2e80",
            }}
          >
            Letshego will endeavour to encrypt all cookies so that no third
            party can view them.
          </p>
        </Row>{" "}
        <Row>
          <p
            style={{
              color: "#2f2e80",
            }}
          >
            Please view our Cookie Notice for more detailed information.
          </p>
        </Row>{" "}
        <Row>
          <p
            style={{
              color: "#2f2e80",
              fontSize: "22px",
              marginTop: "25px",
              fontWeight: "700",
            }}
          >
            Changes to this Privacy Policy
          </p>
        </Row>
        <Row>
          <p
            style={{
              color: "#2f2e80",
            }}
          >
            Letshego Holdings Limited may UPDATE this Privacy Policy at any time
            without notice. Policy changes most often happen due to new laws and
            regulations in the territories WHERE we trade. Letshego Holdings
            Limited will publish all modifications and amendments made to this
            policy. Our Sales and Call Centre Agents will explain the details to
            our customers upon request. Letshego Holdings Limited ensures that
            users acknowledge any policy changes and accept policy amendments in
            their online applications or via the Call Centre before doing
            further business with Letshego.
          </p>
        </Row>{" "}
        <Row>
          <p
            style={{
              color: "#2f2e80",
            }}
          >
            For any questions about this Privacy Policy, or any related issues,
            you may contact the Group on{" "}
            <a
              href="mailto:GroupCorporateAffairs@letshego.com"
              target="_blank"
              style={{ color: "#007bff" }}
            >
              GroupCorporateAffairs@letshego.com
            </a>
            , and we will respond as soon as possible.
          </p>
        </Row>{" "}
        <div style={{ height: "200px" }}></div>
      </Container>
    </div>
  );
}

export default PrivacyPolicy;
