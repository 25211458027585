/* eslint-disable jsx-a11y/anchor-is-valid */
// default imports for react project
import React, { useState } from "react";

// import default style component from react-bootstrap package
import { Col, Container, Row, Button } from "react-bootstrap";

// Import image from assets folder
import {
  comingSoon,
} from "../../assets/images";

// import util to get current window dimensions
import useWindowDimensions from "../../utils/useWindowDimension";

import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import "react-circular-progressbar/dist/styles.css";
import { parseQuery, setParam, trackUserEvents } from "../../utils/utils";
// React functional component starts here..
function Tnc({ isHomeLoading }) {
  // scroll window to top of page on navigation
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Get width from util
  const { width } = useWindowDimensions();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    // dispatch(clearState());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);
  const history = useNavigate();
  const currentToken = useSelector((state) => state.auth.currentUserToken);


  useEffect(() => {
    dispatch({
      type: "CURRENT_USER_TOKEN",
      payload: parseQuery(location.search).token ?? currentToken,
    });
  }, []);

  useEffect(() => {
    setParam(history, currentToken);
  }, [currentToken]);

  return (
    <div style={{ backgroundColor: "#E5E5F5" }}>
      <Container>
        <Row>
          <div
            style={{
              color: "#2f2e80",
              fontSize: "32px",
              marginTop: "25px",
              fontWeight: "700",
            }}
          >
            {" "}
            {" "}
          </div>
        </Row>{" "}

        <Row className="align-items-start">
          <Col md={12}>
            <h3 style={{ fontSize: "26pt", color: "#2F2E80", textAlign: "center" }}>
              LIBERTY LIFE SWAZILAND LIMITED
            </h3>
            <h3 style={{ fontSize: "26pt", color: "#2F2E80", textAlign: "center" }}>
              RETAIL FUNERAL PLAN (COMPREHENSIVE)
            </h3>
            <h3 style={{ fontSize: "26pt", color: "#2F2E80", textAlign: "center" }}>
              TERMS AND CONDITIONS
            </h3>
            <h6 style={{ fontSize: "13pt", color: "#2F2E80" }}>Plan Description</h6>
            <p>This Plan provides a lump sum benefit on death of the Insured Person
              to aid in covering funeral related expenses.</p>

            <h6 style={{ fontSize: "13pt", color: "#2F2E80" }}>Insurer</h6>
            <p>The Insurer is Liberty Life Swaziland Limited, a registered financial
              services provider that underwrites the Funeral Benefit as well as other
              additional benefits under the Funeral Plan.</p>

            <h6 style={{ fontSize: "13pt", color: "#2F2E80" }}>Insured Person</h6>
            <p>The Insured Person shall be the Policyholder. Immediate family
              members can be added under a family benefit, which includes a
              maximum of one spouse and six children.</p>

            <h6 style={{ fontSize: "13pt", color: "#2F2E80" }}>Policyholder</h6>
            <p>The person in whose name the Policy is issued and who is at least 18
              years of age and no more than 65 years of age at the Cover
              Commencement Date.</p>

            <h6 style={{ fontSize: "13pt", color: "#2F2E80" }}>Policy</h6>
            <p>The application form, the Terms and Conditions and any annexures
              or amendments will constitute the Policy.</p>

            <h6 style={{ fontSize: "13pt", color: "#2F2E80" }}>Cover Commencement Date</h6>
            <p>Cover under the Funeral Plan will commence on the first day of the
              month following the receipt, by the Insurer of the Premium, the
              completed application forms and any other substantiating
              documentation.</p>
            <p>Cover for the Accidental Death Benefit will commence on receipt, by
              Liberty Life, from the Policyholder of a fully completed application
              form.</p>

            <h6 style={{ fontSize: "13pt", color: "#2F2E80" }}>Cover</h6>
            <p>Cover refers to the Insured Person’s entitlement to a Benefit on the
              happening of the Insured Event.</p>

            <h6 style={{ fontSize: "13pt", color: "#2F2E80" }}>Benefit</h6>
            <p>Benefit refers to the lump sum monetary consideration as stated in
              the Schedule, paid to the Beneficiary on death.</p>

            <h6 style={{ fontSize: "13pt", color: "#2F2E80" }}>Schedule</h6>
            <p>Shall mean the attached schedule detailing the Policy specific details.</p>

            <h6 style={{ fontSize: "13pt", color: "#2F2E80" }}>Waiting Period</h6>
            <p>No Benefit will be paid should the death occur within 6 months of the
              cover commencement date. No waiting period will be applied for
              deaths from unnatural causes.</p>

            <h6 style={{ fontSize: "13pt", color: "#2F2E80" }}>Spouse</h6>
            <p>The person married to the Policyholder either by civil, tribal, common
              or customary law or under the age of 65 years at the Cover
              Commencement Date. Only one Spouse will be eligible for the
              Spouse’s Cover under this Plan.</p>

            <h6 style={{ fontSize: "13pt", color: "#2F2E80" }}>Child</h6>
            <p>A Child of the Policyholder may be:</p>
            <ol type="a">
              <li>A Child born within wedlock</li>
              <li>A Child born out of wedlock</li>
              <li>An illegitimate child</li>
              <li>An adopted child</li>
              <li>A foster child</li>
              <li>A step child</li>
              <li>A stillborn child, following 28 weeks of pregnancy</li>
            </ol>
            <p>A Child may not be older than 18 years at Cover Commencement
              Date. Cover for a Child will cease once the Child reaches the age of
              21 unless the Child is:</p>
            <ol type="a">
              <li>A student at a registered institution, in which case cover
                shall cease at the age of 25.</li>
              <li>A mentally or physically disabled child who is fully
                dependent on the Policyholder, in which case no cover
                cease age will apply.</li>
            </ol>
            <p>The Plan offers cover for a maximum of 6 children.</p>


            <h6 style={{ fontSize: "13pt", color: "#2F2E80" }}>Insured Event</h6>
            <p>Death of the Insured Person shall be the event that activates a claim
              under this Plan.</p>

            <h6 style={{ fontSize: "13pt", color: "#2F2E80" }}>Premiums</h6>
            <p>Premiums shall be the monetary consideration payable in order for
              Cover to be afforded. Premiums are payable monthly or annually in
              advance.</p>

            <h6 style={{ fontSize: "13pt", color: "#2F2E80" }}>Outstanding premiums</h6>
            <p>If there are outstanding premiums when a Benefit is due, Liberty will
              deduct the arrears from the Benefit payable.</p>

            <h6 style={{ fontSize: "13pt", color: "#2F2E80" }}>Lapsed and Re-instated Funeral Plan</h6>
            <p>In the event that premiums are outstanding for a period longer than
              (1) month the Policy will lapse. A Policy may be re-instated within 30
              days of lapse date if all outstanding premiums are paid. If the Plan
              has been reinstated, cover will commence without any additional
              waiting periods being imposed.</p>

            <h6 style={{ fontSize: "13pt", color: "#2F2E80" }}>Premium rate review</h6>
            <p>Premium rates are guaranteed for a period of 12 months and
              thereafter will be reviewed annually.</p>

            <h6 style={{ fontSize: "13pt", color: "#2F2E80" }}>Benefits Non-Assignable</h6>
            <p>The Benefits under this Agreement cannot be ceded, pledged or
              assigned in any way.</p>

            <h6 style={{ fontSize: "13pt", color: "#2F2E80" }}>Evidence of Health</h6>
            <p>No medical evidence is required to be eligible for the Funeral Plan.</p>

            <h6 style={{ fontSize: "13pt", color: "#2F2E80" }}>Claims</h6>
            <p>Valid claims will be paid upon receipt and verification of all the
              necessary supporting documentation. The Benefits provided in terms
              of this Plan will not be paid unless the Insurer is satisfied as to the
              validity of the claim and the entitlement of the claimant to receive the
              Benefits.</p>
            <p>If the Beneficiary is unable to sign his/her name, a fingerprint of the
              Beneficiary is acceptable provided such finger print is certified on the
              Claim Form at a Police Station in the presence of a Commissioner of
              Oaths.</p>
            <p>Please ensure that the following documentation is presented at the
              time of claim in order to speed up the claims process. These are
              applicable for the optional benefits as well:</p>
            <ul>
              <li>A fully completed funeral claims form</li>
              <li>Death certificate</li>
              <li>Burial Order or Medical Certificate for cause of
                Death</li>
              <li>For an adult (over the age of 18), a certified
                copy of the identity document or proof of identity</li>
              <li>For a Child (as defined), a certified copy of the
                birth certificate or proof of identity</li>
              <li>For a still-born Child, a certified copy of the
                ‘Clinic Card’</li>
              <li>Certified copy of the identity of the claimant</li>
              <li>In the event that the deceased is not the
                Policyholder, proof of relationship (marriage
                certificate, birth certificate etc.).</li>
              <li>In the event of Accidental Death, a police report</li>
            </ul>
            <p>The Insurer reserves the right to call for any further additional
              information in order to assess the claim.</p>

            <h6 style={{ fontSize: "13pt", color: "#2F2E80" }}>Notification Period</h6>
            <p>A claim must be submitted within (6) months of the date of death of
              the Insured. The Insurer reserves the right to reject a claim that is not
              submitted within the Notification Period.</p>

            <h6 style={{ fontSize: "13pt", color: "#2F2E80" }}>Premium Waiver on Death Benefit</h6>
            <p>On death of the Policyholder the immediate family will remain covered
              under this Plan for a period of 12 months with no premium required.
              This benefit is payable if the policy has been in-force for a minimum of
              12 months and all premiums have been paid. Extended family,
              parents and parents-in-law are excluded from this benefit. No
              changes to the policy will be allowed during this waiver period
              including the addition of insured lives or changes in benefit levels.
              Following the death of the Policyholder, the spouse may opt to
              become the main member and take over the premiums due for the
              policy to remain in-force at the end of the waiver period. No waiting
              period shall apply and the Benefit will remain the same.</p>

            <h6 style={{ fontSize: "13pt", color: "#2F2E80" }}>Grocery Benefit</h6>
            <p>On death of the Policyholder/Spouse the grocery benefit will pay an
              additional lump sum benefit to the Beneficiary. This Benefit is
              optional and must be selected at Policy inception.</p>

            <h6 style={{ fontSize: "13pt", color: "#2F2E80" }}>Tombstone/Other Benefit</h6>
            <p>On death of the Policyholder/Spouse the tombstone benefit will pay
              an additional lump sum benefit to the Beneficiary. This Benefit is
              optional and must be selected at Policy inception.</p>

            <h6 style={{ fontSize: "13pt", color: "#2F2E80" }}>Accident Death Benefit</h6>
            <p>On death of the Policyholder/Spouse due to unnatural causes, this
              benefit will pay an additional lump sum benefit to the Beneficiary
              equal to a percentage of the funeral benefit.</p>

            <h6 style={{ fontSize: "13pt", color: "#2F2E80" }}>Parents and Parent-in-Laws</h6>
            <p>This Plan will cover a maximum of two parents and two parents in
              law. A Parent includes a biological Parent or an adoptive Parent who
              is under the age of (75) at the Cover Commencement Date. A parent-
              in-Law is a parent of the Spouse of the Policyholder. Parents and
              parents-in-law must be added at policy inception and cannot be
              added at a later stage. An additional premium will be charged per
              Parent.</p>

            <h6 style={{ fontSize: "13pt", color: "#2F2E80" }}>Extended Family Benefit</h6>
            <p>This Plan will cover a maximum of 6 extended family members which
              are below the age of 65 at cover commencement. Extended family
              members must be added at policy inception and cannot be added at a
              later stage. An additional Premium will be charged per Extended
              family Member</p>
            <p>Extended Family will include the following:</p>
            <ol type="a">
              <li>Any Child above the maximum number allowed to be covered
                under the Family Benefit;</li>
              <li>A blood relative of the Policyholder that is financially
                dependent on the Policyholder.</li>
              <li>Additional spouses as permissible in law and in addition to the
                spouse elected in the Family Benefit.</li>
            </ol>
            <h6 style={{ fontSize: "13pt", color: "#2F2E80" }}>Beneficiary</h6>
            <p>In the event of the Policyholder’s death, the Benefit will be paid to:</p>
            <ul>
              <li>The Beneficiary as nominated on the application or
                amendment form.</li>
              <li>If no Beneficiary is nominated, the Spouse will be paid the
                Benefit</li>
              <li>If there is no Spouse on record, the Benefit will be paid to
                an Insured Person who qualifies as the closest relative on
                the Benefit</li>
              <li>Failing all of the above, the Benefit will be paid to the
                deceased's estate.</li>
            </ul>
            <p>In the event of the death of an Insured Person other than the
              Policyholder, the Benefit will be paid to the Policyholder.</p>

            <h6 style={{ fontSize: "13pt", color: "#2F2E80" }}>Document Submission Period</h6>
            <p>All relevant documentation pertaining to a claim must be submitted
              within (12) months of the date of death of the Insured. The Insurer
              reserves the right to reject a claim whereby the relevant
              documentation is not submitted within the submission period.</p>

            <h6 style={{ fontSize: "13pt", color: "#2F2E80" }}>Upgrade and Downgrade of Benefit</h6>
            <p>No upgrades or downgrades will be allowed under this policy.</p>

            <h6 style={{ fontSize: "13pt", color: "#2F2E80" }}>Inclusion of New Insured Lives</h6>
            <p>All Insured Persons must be included in the policy at inception. The
              exception to this is:</p>
            <ul>
              <li>A spouse may be included in a policy after inception
                provided this is done within (6) months of marriage. Proof of
                the marriage must be submitted.</li>
              <li>A new born child may be included in the policy after
                inception provided this is done within (6 ) months of birth. A
                Birth certificate is required. Stillborn babies will be covered
                under the policy up to the maximum number of children and
                provided a clinic card is submitted.</li>
            </ul>

            <h6 style={{ fontSize: "13pt", color: "#2F2E80" }}>Exclusions</h6>
            <p>The Insurer will not be liable for any claim arising whether directly or
              indirectly as a result of:</p>
            <ul>
              <li>Invasion or act of foreign enemy
              </li>
              <li>Hostilities (whether war is declared or not) or
                unrest
              </li>
              <li>War, riots and/or acts of terrorism
              </li>
              <li>Involvement in criminal activity
              </li>
              <li>Military or usurped power
              </li>
              <li>Suicide within the first 24 months following the
                commencement date.
              </li>
              <li>The effects of radioactivity or nuclear explosion
              </li>
              <li>Accidental Death as a result of, private flying,
                hazardous sports or any illegal acts where the
                deceased was directly involved.</li>
            </ul>

            <h6 style={{ fontSize: "13pt", color: "#2F2E80" }}>Termination of cover</h6>
            <p>No Benefits will be paid from claims occurring after the lapse of a
              Policy for any reason. The Insurer may terminate an Insured Person’s
              Cover without notice if the Insured Person does not comply with the
              Terms and Conditions of the Funeral plan. A Policyholder may, at any
              time, terminate the cover of any of the other Insured Persons included
              in the Funeral Plan. On the event of any other Insured Person’s
              Cover being terminated that Insured Person may not be readmitted to
              the Funeral Plan.</p>
            <p>A Policyholder’s Funeral cover will end on the earliest of:</p>
            <ol type="a">
              <li>the end of the grace period;</li>
              <li>the last day of the month in which the
                Policyholder elects to terminate the Policy</li>
              <li>on death of the Policyholder</li>
            </ol>
            <p>Cover in respect of dependants will end immediately:</p>
            <ol type="a">
              <li>In the case of a Child, reaching the Child’s
                Maximum Cover Age;</li>
              <li>In the case of a Spouse, ceasing to be defined
                as such</li>
              <li>The Policyholder ceasing to be defined as such.</li>
              <li>On the death of the Policyholder;</li>
              <li>At the end of the waiver period ;</li>
              <li>Termination of membership for the Policyholder;</li>
              <li>The Insured person failing to be defined as such.</li>
            </ol>

            <h6 style={{ fontSize: "13pt", color: "#2F2E80" }}>Cancellation</h6>
            <p>The Insurer may cancel this Policy at any time by giving one calendar
              months’ notice in writing to the last known contact details. No new
              Insured Person may be added after the notice of cancellation has
              been delivered. No refund of premiums whether pro-rata or otherwise
              will be given on cancellation of this Policy. The Policyholder may
              cancel the Policy at any time by giving one calendar months’ notice to
              the Insurer.</p>

            <h6 style={{ fontSize: "13pt", color: "#2F2E80" }}>Governing Laws</h6>
            <p>The laws of Swaziland, whose courts shall have jurisdiction in any
              dispute arising hereunder, will govern this Policy. The Benefits
              payable and the premium rates under this Policy may be changed if
              any legislation is changed. If these changes are made, the
              Policyholder will be notified in writing.</p>

            <h6 style={{ fontSize: "13pt", color: "#2F2E80" }}>Territorial limits</h6>
            <p>The Insured will be covered while domiciled in the country where the
              policy was issued. The Insured will also be covered outside the
              domicile country for a maximum period of 12 months, provided
              premiums are continued to be paid. Should the Insured be outside the
              domicile country for longer than 12 months the policy will lapse.
              Written permission may be sent to the Insurer to request an extension
              of stay outside the domicile country. Should the Insured return to the
              domicile country and subsequently leave before 30 days have expired
              then this will not constitute a return to the domicile country.</p>

            <h6 style={{ fontSize: "13pt", color: "#2F2E80" }}>Surrender values</h6>
            <p>No surrender values are payable under this Policy.</p>

            <h6 style={{ fontSize: "13pt", color: "#2F2E80" }}>Fraud</h6>
            <p>If any claim under this Policy is fraudulent in any manner all Benefits
              will be forfeited, the Policy cancelled and no refund of premiums will
              be payable.</p>

            <h6 style={{ fontSize: "13pt", color: "#2F2E80" }}>Currency</h6>
            <p>Premiums and Benefits are expressed and payable in the legal tender
              of Swaziland.</p>

            <h6 style={{ fontSize: "13pt", color: "#2F2E80" }}>Revocation, Alteration, Amendment</h6>
            <p>The Insurer reserves the right to amend, revoke, vary or alter any
              terms and conditions of this Policy provided that the Policyholder is
              given thirty (30) days’ notice of such an amendment.</p>

            <h6 style={{ fontSize: "13pt", color: "#2F2E80" }}>Agreement</h6>
            <p>The application form, the Terms and Conditions and any amendments
              thereto constitute the sole Agreement between the parties. No
              contrary representation or agreement shall be of any force unless
              reduced to writing and signed by both parties.</p>

            <h6 style={{ fontSize: "13pt", color: "#2F2E80" }}>Dispute Resolution /Queries</h6>
            <p>In the event of a dispute or any queries, please contact the following:</p>

            <h6 style={{ fontSize: "13pt", color: "#2F2E80" }}>The Principal Officer</h6>
            <p>Liberty Life Swaziland Limited<br />
              1st Floor Ingcamu Building,<br />
              Mhlambanyatsi Road, Mbabane, Swaziland<br />
              Tel: +268 2404 3444<br />
              Fax: +268 2404 1803</p>
          </Col>

        </Row>
        <Row className="align-items-start">
          <Col md={12}>
            <h3 style={{ fontSize: "26pt", color: "#2F2E80", textAlign: "center" }}>
              LIBERTY LIFE SWAZILAND LIMITED
            </h3>
            <h3 style={{ fontSize: "26pt", color: "#2F2E80", textAlign: "center" }}>
              RETAIL FUNERAL PLAN (BASIC)
            </h3>
            <h3 style={{ fontSize: "26pt", color: "#2F2E80", textAlign: "center" }}>
              TERMS AND CONDITIONS
            </h3>
            <h6 style={{ fontSize: "13pt", color: "#2F2E80" }}>Plan Description</h6>
            <p>This Plan provides a lump sum benefit on death of the Insured Person
              to aid in covering funeral related expenses.</p>

            <h6 style={{ fontSize: "13pt", color: "#2F2E80" }}>Insurer</h6>
            <p>The Insurer is Liberty Life Swaziland Limited, a registered financial
              services provider that underwrites the Funeral Benefit as well as other
              additional benefits under the Funeral Plan.</p>

            <h6 style={{ fontSize: "13pt", color: "#2F2E80" }}>Insured Person</h6>
            <p>The Insured Person shall be the Policyholder. Immediate family
              members can be added under a family benefit, which includes a
              maximum of one spouse and six children.</p>

            <h6 style={{ fontSize: "13pt", color: "#2F2E80" }}>Policyholder</h6>
            <p>The person in whose name the Policy is issued and who is at least 18
              years of age and no more than 65 years of age at the Cover
              Commencement Date.</p>

            <h6 style={{ fontSize: "13pt", color: "#2F2E80" }}>Policy</h6>
            <p>The application form, the Terms and Conditions and any annexures
              or amendments will constitute the Policy.</p>

            <h6 style={{ fontSize: "13pt", color: "#2F2E80" }}>Cover Commencement Date</h6>
            <p>For cash Policies, cover will commence on acceptance of the fully
completed application documentation by Liberty Life and payment of
the first premium.</p>
            <p>For debit order Policies, cover will commence on acceptance of the
fully completed application documentation by Liberty Life. The
premium will be collected on the next selected debit order date.</p>

            <h6 style={{ fontSize: "13pt", color: "#2F2E80" }}>Cover</h6>
            <p>Cover refers to the Insured Person’s entitlement to a Benefit on the
              happening of the Insured Event.</p>

            <h6 style={{ fontSize: "13pt", color: "#2F2E80" }}>Benefit</h6>
            <p>Benefit refers to the lump sum monetary consideration as stated in
              the Schedule, paid to the Beneficiary on death.</p>

            <h6 style={{ fontSize: "13pt", color: "#2F2E80" }}>Schedule</h6>
            <p>Shall mean the attached schedule detailing the Policy specific details.</p>

            <h6 style={{ fontSize: "13pt", color: "#2F2E80" }}>Waiting Period</h6>
            <p>No Benefit will be paid should the death occur within 6 months of the
              cover commencement date. No waiting period will be applied for
              deaths from unnatural causes.</p>

            <h6 style={{ fontSize: "13pt", color: "#2F2E80" }}>Spouse</h6>
            <p>The person married to the Policyholder either by civil, tribal, common
              or customary law or under the age of 65 years at the Cover
              Commencement Date. Only one Spouse will be eligible for the
              Spouse’s Cover under this Plan.</p>

            <h6 style={{ fontSize: "13pt", color: "#2F2E80" }}>Child</h6>
            <p>A Child of the Policyholder may be:</p>
            <ol type="a">
              <li>A Child born within wedlock</li>
              <li>A Child born out of wedlock</li>
              <li>An illegitimate child</li>
              <li>An adopted child</li>
              <li>A foster child</li>
              <li>A step child</li>
              <li>A stillborn child, following 28 weeks of pregnancy</li>
            </ol>
            <p>A Child may not be older than 18 years at Cover Commencement
              Date. Cover for a Child will cease once the Child reaches the age of
              21 unless the Child is:</p>
            <ol type="a">
              <li>A student at a registered institution, in which case cover
                shall cease at the age of 25.</li>
              <li>A mentally or physically disabled child who is fully
                dependent on the Policyholder, in which case no cover
                cease age will apply.</li>
            </ol>
            <p>The Plan offers cover for a maximum of 6 children.</p>


            <h6 style={{ fontSize: "13pt", color: "#2F2E80" }}>Insured Event</h6>
            <p>Death of the Insured Person shall be the event that activates a claim
              under this Plan.</p>

            <h6 style={{ fontSize: "13pt", color: "#2F2E80" }}>Premiums</h6>
            <p>Premiums shall be the monetary consideration payable in order for
              Cover to be afforded. Premiums are payable monthly or annually in
              advance.</p>

            <h6 style={{ fontSize: "13pt", color: "#2F2E80" }}>Outstanding premiums</h6>
            <p>If there are outstanding premiums when a Benefit is due, Liberty will
              deduct the arrears from the Benefit payable.</p>

            <h6 style={{ fontSize: "13pt", color: "#2F2E80" }}>Lapsed and Re-instated Funeral Plan</h6>
            <p>In the event that premiums are outstanding for a period longer than
              (1) month the Policy will lapse. A Policy may be re-instated within 30
              days of lapse date if all outstanding premiums are paid. If the Plan
              has been reinstated, cover will commence without any additional
              waiting periods being imposed.</p>

            <h6 style={{ fontSize: "13pt", color: "#2F2E80" }}>Premium rate review</h6>
            <p>Premium rates are guaranteed for a period of 12 months and
              thereafter will be reviewed annually.</p>

            <h6 style={{ fontSize: "13pt", color: "#2F2E80" }}>Benefits Non-Assignable</h6>
            <p>The Benefits under this Agreement cannot be ceded, pledged or
              assigned in any way.</p>

            <h6 style={{ fontSize: "13pt", color: "#2F2E80" }}>Evidence of Health</h6>
            <p>No medical evidence is required to be eligible for the Funeral Plan.</p>

            <h6 style={{ fontSize: "13pt", color: "#2F2E80" }}>Claims</h6>
            <p>Valid claims will be paid upon receipt and verification of all the
              necessary supporting documentation. The Benefits provided in terms
              of this Plan will not be paid unless the Insurer is satisfied as to the
              validity of the claim and the entitlement of the claimant to receive the
              Benefits.</p>
            <p>If the Beneficiary is unable to sign his/her name, a fingerprint of the
              Beneficiary is acceptable provided such finger print is certified on the
              Claim Form at a Police Station in the presence of a Commissioner of
              Oaths.</p>
            <p>Please ensure that the following documentation is presented at the
              time of claim in order to speed up the claims process. These are
              applicable for the optional benefits as well:</p>
            <ul>
              <li>A fully completed funeral claims form</li>
              <li>Death certificate</li>
              <li>Burial Order or Medical Certificate for cause of
                Death</li>
              <li>For an adult (over the age of 18), a certified
                copy of the identity document or proof of identity</li>
              <li>For a Child (as defined), a certified copy of the
                birth certificate or proof of identity</li>
              <li>For a still-born Child, a certified copy of the
                ‘Clinic Card’</li>
              <li>Certified copy of the identity of the claimant</li>
              <li>In the event that the deceased is not the
                Policyholder, proof of relationship (marriage
                certificate, birth certificate etc.).</li>
            </ul>
            <p>The Insurer reserves the right to call for any further additional
              information in order to assess the claim.</p>

            <h6 style={{ fontSize: "13pt", color: "#2F2E80" }}>Notification Period</h6>
            <p>A claim must be submitted within (6) months of the date of death of
              the Insured. The Insurer reserves the right to reject a claim that is not
              submitted within the Notification Period.</p>

            <h6 style={{ fontSize: "13pt", color: "#2F2E80" }}>Premium Waiver on Death Benefit</h6>
            <p>On death of the Policyholder the immediate family will remain covered
              under this Plan for a period of 12 months with no premium required.
              This benefit is payable if the policy has been in-force for a minimum of
              12 months and all premiums have been paid. Extended family,
              parents and parents-in-law are excluded from this benefit. No
              changes to the policy will be allowed during this waiver period
              including the addition of insured lives or changes in benefit levels.
              Following the death of the Policyholder, the spouse may opt to
              become the main member and take over the premiums due for the
              policy to remain in-force at the end of the waiver period. No waiting
              period shall apply and the Benefit will remain the same.</p>

            <h6 style={{ fontSize: "13pt", color: "#2F2E80" }}>Parents and Parent-in-Laws</h6>
            <p>This Plan will cover a maximum of two parents and two parents in
              law. A Parent includes a biological Parent or an adoptive Parent who
              is under the age of (75) at the Cover Commencement Date. A parent-
              in-Law is a parent of the Spouse of the Policyholder. Parents and
              parents-in-law must be added at policy inception and cannot be
              added at a later stage. An additional premium will be charged per
              Parent.</p>

            <h6 style={{ fontSize: "13pt", color: "#2F2E80" }}>Extended Family Benefit</h6>
            <p>This Plan will cover a maximum of 6 extended family members which
              are below the age of 65 at cover commencement. Extended family
              members must be added at policy inception and cannot be added at a
              later stage. An additional Premium will be charged per Extended
              family Member</p>
            <p>Extended Family will include the following:</p>
            <ol type="a">
              <li>Any Child above the maximum number allowed to be covered
                under the Family Benefit;</li>
              <li>A blood relative of the Policyholder that is financially
                dependent on the Policyholder.</li>
              <li>Additional spouses as permissible in law and in addition to the
                spouse elected in the Family Benefit.</li>
            </ol>
            <h6 style={{ fontSize: "13pt", color: "#2F2E80" }}>Beneficiary</h6>
            <p>In the event of the Policyholder’s death, the Benefit will be paid to:</p>
            <ul>
              <li>The Beneficiary as nominated on the application or
                amendment form.</li>
              <li>If no Beneficiary is nominated, the Spouse will be paid the
                Benefit</li>
              <li>If there is no Spouse on record, the Benefit will be paid to
                an Insured Person who qualifies as the closest relative on
                the Benefit</li>
              <li>Failing all of the above, the Benefit will be paid to the
                deceased's estate.</li>
            </ul>
            <p>In the event of the death of an Insured Person other than the
              Policyholder, the Benefit will be paid to the Policyholder.</p>

            <h6 style={{ fontSize: "13pt", color: "#2F2E80" }}>Document Submission Period</h6>
            <p>All relevant documentation pertaining to a claim must be submitted
              within (12) months of the date of death of the Insured. The Insurer
              reserves the right to reject a claim whereby the relevant
              documentation is not submitted within the submission period.</p>

            <h6 style={{ fontSize: "13pt", color: "#2F2E80" }}>Upgrade and Downgrade of Benefit</h6>
            <p>No upgrades or downgrades will be allowed under this policy.</p>

            <h6 style={{ fontSize: "13pt", color: "#2F2E80" }}>Inclusion of New Insured Lives</h6>
            <p>All Insured Persons must be included in the policy at inception. The
              exception to this is:</p>
            <ul>
              <li>A spouse may be included in a policy after inception
                provided this is done within (6) months of marriage. Proof of
                the marriage must be submitted.</li>
              <li>A new born child may be included in the policy after
                inception provided this is done within (6 ) months of birth. A
                Birth certificate is required. Stillborn babies will be covered
                under the policy up to the maximum number of children and
                provided a clinic card is submitted.</li>
            </ul>

            <h6 style={{ fontSize: "13pt", color: "#2F2E80" }}>Exclusions</h6>
            <p>The Insurer will not be liable for any claim arising whether directly or
              indirectly as a result of:</p>
            <ul>
              <li>Invasion or act of foreign enemy
              </li>
              <li>Hostilities (whether war is declared or not) or
                unrest
              </li>
              <li>War, riots and/or acts of terrorism
              </li>
              <li>Involvement in criminal activity
              </li>
              <li>Military or usurped power
              </li>
              <li>Suicide within the first 24 months following the
                commencement date.
              </li>
              <li>The effects of radioactivity or nuclear explosion
              </li>
              <li>Accidental Death as a result of, private flying,
                hazardous sports or any illegal acts where the
                deceased was directly involved.</li>
            </ul>

            <h6 style={{ fontSize: "13pt", color: "#2F2E80" }}>Termination of cover</h6>
            <p>No Benefits will be paid from claims occurring after the lapse of a
              Policy for any reason. The Insurer may terminate an Insured Person’s
              Cover without notice if the Insured Person does not comply with the
              Terms and Conditions of the Funeral plan. A Policyholder may, at any
              time, terminate the cover of any of the other Insured Persons included
              in the Funeral Plan. On the event of any other Insured Person’s
              Cover being terminated that Insured Person may not be readmitted to
              the Funeral Plan.</p>
            <p>A Policyholder’s Funeral cover will end on the earliest of:</p>
            <ol type="a">
              <li>the end of the grace period;</li>
              <li>the last day of the month in which the
                Policyholder elects to terminate the Policy</li>
              <li>on death of the Policyholder</li>
            </ol>
            <p>Cover in respect of dependants will end immediately:</p>
            <ol type="a">
              <li>In the case of a Child, reaching the Child’s
                Maximum Cover Age;</li>
              <li>In the case of a Spouse, ceasing to be defined
                as such</li>
              <li>The Policyholder ceasing to be defined as such.</li>
              <li>On the death of the Policyholder;</li>
              <li>At the end of the waiver period ;</li>
              <li>Termination of membership for the Policyholder;</li>
              <li>The Insured person failing to be defined as such.</li>
            </ol>

            <h6 style={{ fontSize: "13pt", color: "#2F2E80" }}>Cancellation</h6>
            <p>The Insurer may cancel this Policy at any time by giving one calendar
              months’ notice in writing to the last known contact details. No new
              Insured Person may be added after the notice of cancellation has
              been delivered. No refund of premiums whether pro-rata or otherwise
              will be given on cancellation of this Policy. The Policyholder may
              cancel the Policy at any time by giving one calendar months’ notice to
              the Insurer.</p>

            <h6 style={{ fontSize: "13pt", color: "#2F2E80" }}>Governing Laws</h6>
            <p>The laws of Swaziland, whose courts shall have jurisdiction in any
              dispute arising hereunder, will govern this Policy. The Benefits
              payable and the premium rates under this Policy may be changed if
              any legislation is changed. If these changes are made, the
              Policyholder will be notified in writing.</p>

            <h6 style={{ fontSize: "13pt", color: "#2F2E80" }}>Territorial limits</h6>
            <p>The Insured will be covered while domiciled in the country where the
              policy was issued. The Insured will also be covered outside the
              domicile country for a maximum period of 12 months, provided
              premiums are continued to be paid. Should the Insured be outside the
              domicile country for longer than 12 months the policy will lapse.
              Written permission may be sent to the Insurer to request an extension
              of stay outside the domicile country. Should the Insured return to the
              domicile country and subsequently leave before 30 days have expired
              then this will not constitute a return to the domicile country.</p>

            <h6 style={{ fontSize: "13pt", color: "#2F2E80" }}>Surrender values</h6>
            <p>No surrender values are payable under this Policy.</p>

            <h6 style={{ fontSize: "13pt", color: "#2F2E80" }}>Fraud</h6>
            <p>If any claim under this Policy is fraudulent in any manner all Benefits
              will be forfeited, the Policy cancelled and no refund of premiums will
              be payable.</p>

            <h6 style={{ fontSize: "13pt", color: "#2F2E80" }}>Currency</h6>
            <p>Premiums and Benefits are expressed and payable in the legal tender
              of Swaziland.</p>

            <h6 style={{ fontSize: "13pt", color: "#2F2E80" }}>Revocation, Alteration, Amendment</h6>
            <p>The Insurer reserves the right to amend, revoke, vary or alter any
              terms and conditions of this Policy provided that the Policyholder is
              given thirty (30) days’ notice of such an amendment.</p>

            <h6 style={{ fontSize: "13pt", color: "#2F2E80" }}>Agreement</h6>
            <p>The application form, the Terms and Conditions and any amendments
              thereto constitute the sole Agreement between the parties. No
              contrary representation or agreement shall be of any force unless
              reduced to writing and signed by both parties.</p>

            <h6 style={{ fontSize: "13pt", color: "#2F2E80" }}>Dispute Resolution /Queries</h6>
            <p>In the event of a dispute or any queries, please contact the following:</p>

            <h6 style={{ fontSize: "13pt", color: "#2F2E80" }}>The Principal Officer</h6>
            <p>Liberty Life Swaziland Limited<br />
              1st Floor Ingcamu Building,<br />
              Mhlambanyatsi Road, Mbabane, Swaziland<br />
              Tel: +268 2404 3444<br />
              Fax: +268 2404 1803</p>
          </Col>

        </Row>
        <div style={{ height: "220px" }}></div>
      </Container>
    </div>
  );
}

export default Tnc;
