export const travelSuperiorPlan = [
  {
    title: "How do one qualify for any of the funeral plan covers?",
    subAnswer: [
      "The benefit can be taken up on a voluntary basis. The product can cover a main member only or a family. A family can consist of a maximum of one main member, maximum one spouse and a maximum of 6 children. The Insured Person shall be the Policyholder. Immediate family members can be added under a family benefit, which includes a maximum of one spouse and six children. This Plan provides a lump sum benefit on death of the Insured Person to aid in covering funeral related expenses.Does the funeral plan cover parents and parents’ in-law as well as extended family members?A maximum of 2 parents and 2 parents in law can be added on a voluntary basis. The benefit for parents will be a choice of E5000, E10000 and E20000 and cannot be greater than the main member benefit. A maximum of 6 extended family members can be added on a voluntary basis. The benefit for extended family will be a choice of E5000 or E10000 and cannot be greater than the main member benefit. Extended family benefit applies only to blood relatives that are financially dependent on the main member.Is the any waiting period for one to be covered after completing the application process?6 month waiting period for natural deaths. No waiting period is applicable for unnatural deaths. Cover for accidental death will start on the receipt of a fully completed application form and supporting documents. Cover for natural deaths will commence on receipt of first premium. On death of the Policyholder/Spouse due to unnatural causes, this benefit will pay an additional lump sum benefit to the Beneficiary equal to a percentage of the funeral benefit.How long is the period to submit all the documents required for a claim?All relevant documentation pertaining to a claim must be submitted within (12) months of the date of death of the Insured. The Insurer reserves the right to reject a claim whereby the relevant documentation is not submitted within the submission period.",
    ],
  },
  {
    title: "What are the documents required for submitting a claim?",
    subAnswer: [
      "The following documents are required in order to lodge a claim:",
      "Fully completed Claim Form; and",
      "Certified copy of I.D. of the deceased;",
      "Certified copy of I.D. of the beneficiary;",
      "Proof of relationship of deceased to main member;",
      "Police report (if death was due to an accident);",
      "Any other documentation as required to validate claims.",
    ],
  },
  {
    title:
      "Will I need to undergo medical examinations for my application to be successful?",
    subAnswer: [
      "No medical evidence is required to be eligible for the Funeral Plan.",
      "Can my policy be cancelled and will I get a refund?",
      "The Insurer may cancel the Policy at any time by giving one calendar months’ notice in writing to the last known contact details. No new Insured Person may be added after the notice of cancellation has been delivered. No refund of premiums whether pro-rata or otherwise will be given on cancellation of this Policy. The Policyholder may cancel the Policy at any time by giving one calendar months’ notice to the Insurer.",
      "What are the Premium Payment Methods & Frequency?",
      "Premiums can be paid monthly and annually as a lump sum in advance. They can be paid in cash or with a debit order payment. Premiums can be reviewed annually.",
    ],
  },
  {
    title:
      "How long is the period to submit all the documents required for a claim?",
    subAnswer: [
      " All relevant documentation pertaining to a claim must be submitted within (12) months of the date of death of the Insured. The Insurer reserves the right to reject a claim whereby the relevant documentation is not submitted within the submission period.",
    ],
  },

  {
    title: "Can my policy be cancelled and will I get a refund?",
    subAnswer: [
      "The Insurer may cancel the Policy at any time by giving one calendar months’ notice in writing to the last known contact details. No new Insured Person may be added after the notice of cancellation has been delivered. No refund of premiums whether pro-rata or otherwise will be given on cancellation of this Policy. The Policyholder may cancel the Policy at any time by giving one calendar months’ notice to the Insurer.",
    ],
  },
  {
    title: "What are the Premium Payment Methods & Frequency?",
    subAnswer: [
      "Premiums can be paid monthly and annually as a lump sum in advance. They can be paid in cash or with a debit order payment. Premiums can be reviewed annually.",
    ],
  },
];
