import logo from "./logo.svg";
import "./App.css";
import { Route, useLocation, useNavigate } from "react-router-dom";
import { Routes } from "react-router";
import "bootstrap/dist/css/bootstrap.min.css";
import Form from "./screens/Forms/Forms";
import Homepage from "./screens/Homepage/Home";
import Demo from "./screens/Demo/index";
import "./components/common.css";

import Disclosure from "./screens/Disclosures/disclosure";
import PrivacyPolicy from "./screens/Disclosures/privacyPolicy";

import PaymentSuccess from "./screens/Forms/Steps/PaymentSuccess";
import Errorpage from "./screens/Forms/Steps/Errorpage";
import { useEffect, useState } from "react";
import useWindowDimensions from "./utils/useWindowDimension";
import { useDispatch, useSelector } from "react-redux";
import { getAgentDetails, login, updateLoading } from "./store/actions/auth";
import { clearRiskState, clearState } from "./store/actions/products";
import Loader from "./components/Loader";
import { parseQuery, setParam } from "./utils/utils";

import Navbar from "./components/Navbar/Navbar";
import TopNavbar from "./components/Navbar/TopNavbar";
// import TopNavbar from "../../components/Topbar/Topbar";
import Footer from "./components/Navbar/Footer";
import Contact from "./screens/Contact/contact";
import About from "./screens/About/about";
import Products from "./screens/Products/products";
import Faqs from "./screens/Faqs/faqs";
import Tnc from "./screens/TnC/tnc";

console.log = () => {};
console.error = () => {};
console.warn = () => {};

function App() {
  const { width } = useWindowDimensions();

  const dispatch = useDispatch();
  const history = useNavigate();
  const currentToken = useSelector((state) => state.auth.currentUserToken);
  const companyDetails = useSelector((state) => state.auth.companyDetails);
  const location = useLocation();
  const riskAttributes = useSelector((state) => state.products.riskAttributes);

  const [ishomeLoading, setIsHomeLoading] = useState(true);
  const agentCode = useSelector((state) => state.auth.agentCode);

  useEffect(() => {
    dispatch({
      type: "CURRENT_USER_TOKEN",
      payload: parseQuery(location.search).token ?? currentToken,
    });
  }, []);

  useEffect(() => {
    setParam(history, currentToken);
  }, [currentToken]);

  useEffect(() => {
    if (location.pathname !== "/forms") {
      dispatch(clearRiskState());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  // Loader state form
  const isLoading = useSelector((state) => state.auth.loading);
  return (
    <>
      {/* <BrowserRouter> */}
      {/* {width > 430 ? <TopNavbar /> : null} */}
      {width > 475 ? <TopNavbar /> : null}

      <Navbar />
      <Routes>
        <Route path="/forms" element={<Form />} />
        <Route path="/" element={<Homepage isHomeLoading={ishomeLoading} />} />
        <Route path="/dummy" element={<Demo />} />
        <Route path="/contact" element={<Contact />} />
        {/* <------- Disclosure page --------> */}
        <Route path="/disclaimer" element={<Disclosure />} />
        {/* <------- PrivacyPolicy page --------> */}
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />

        <Route path="/about" element={<About />} />
        <Route path="/products" element={<Products />} />
        <Route path="/faqs" element={<Faqs />} />
        <Route path="/terms-and-conditions" element={<Tnc />} />
        <Route path="/payment-success" element={<PaymentSuccess />} />
        <Route path="/payment-failure" element={<Errorpage />} />
      </Routes>
      <div style={{ height: "3px", backgroundColor: "#cfcfd3" }}></div>
      <Footer />
      {/* </BrowserRouter> */}
    </>
  );
}

export default App;
