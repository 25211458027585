// default imports for react project
import React,  { useEffect, useState } from "react";

// import default styles from react-bootstrap package
import { Col, Row } from "react-bootstrap";

// Import images from assets folder

//   import custom component
import BodyButton from "../Buttons/BodyButton";
import { box , liberty } from "../../assets/images";
import { useNavigate, useLocation } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import {
  getProductRisks,
  getProductRisksAttributes,
  getProductTypeDetails,
} from "../../store/actions/products";
import {
  getAgentOnboarding,
  login,
} from "../../store/actions/auth";
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { parseQuery, setParam, trackUserEvents } from "../../utils/utils";


// React functional component starts here..
function Card2({ width }) {
  const location = useLocation();
  useEffect(() => {
    // dispatch(clearState());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);
  const history = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const currentToken =  useSelector(state => state.auth.currentUserToken)
  const agentCode = useSelector((state) => state.auth.agentCode);
  const agentOnBoarding = useSelector((state) => state.auth.agentOnboarding);

  const [isCounter, setIsCounter] = useState(false);
  let timer = null;
  const countDown = useSelector((state) => state?.products?.countDown?.count) ?? 0;


 useEffect(() => {
  dispatch({type:"CURRENT_USER_TOKEN", payload: parseQuery(location.search).token ?? currentToken})
},[])


  useEffect(() => {
    setParam(history,currentToken)
  },[currentToken])

  const handleSubmit = async () => {
    try{
      setIsLoading(true);
      setIsCounter(true);
      if (Object.keys(agentOnBoarding || {}).length === 0) {
        await dispatch(getAgentOnboarding(agentCode));
      }
      const riskRes = await dispatch(getProductRisks());
      await dispatch(getProductTypeDetails());
      riskRes?.risks?.forEach(async (risk, index) => {
        await dispatch(getProductRisksAttributes(risk.instanceId));
        if (riskRes?.risks?.length - 1 === index) {
          history("/forms");
          setIsLoading(false);
        }
      });
      setIsCounter(false);
      //trackUserEvents
      trackUserEvents("sz_insurnace_get_a_quote_cta", {});
      return;
    }catch(err){
      console.log(err);
    }
  }



  return (
    <div>
      <Col lg xs={12}>
        <Row className="m-0 pb-5 card__homepage">
          <div
            style={{
              backgroundColor: "#E5E5F5",
              padding: width < 430 ? "0px" : "",
            }}
          >
            <Row className="m-4">
              <Col lg={10}>
                <h3 style={{ fontSize: "26pt", color: "#2F2E80" }}>
                  It's never been easier to get the insurance cover.{" "}
                </h3>
                <h6 style={{ fontSize: "13pt", color: "#2F2E80" }}>
                  If something was to happen to you, you can make sure your
                  family is protected
                </h6>
              </Col>
              <Col lg={2}>
                <Row>
                  <h6
                    style={{
                      color: "#56616C",
                      fontSize: "8pt",
                      fontWeight: "bold",
                      textAlign: "center",
                      marginTop: "21px",
                    }}
                  >
                    Policy underwritten by
                  </h6>
                </Row>
                <Row>
                  <div
                    className={`d-flex ${
                      width > 770
                        ? "justify-content-start"
                        : "justify-content-center"
                    }`}
                  >
                    <img
                      src={liberty}
                      style={{
                        marginLeft: "36.2px",
                        width: "95px",
                        marginTop: "1px",
                      }}
                      alt="sanlam"
                    />
                  </div>
                </Row>
              </Col>
            </Row>
            <Row>
              <hr
                style={{
                  color: "white",
                  height: 2,
                  width: "97%",
                  margin: "auto",
                }}
              />
            </Row>
            <Row className="secondary-container p-3 ">
              <Row className="secondary-container p-3 ">
                <Col lg={7}>
                  <Row>
                    {/* <Col lg={1}></Col> */}
                    <Col lg={3} style={{ textAlign: "right" }}>
                      <img
                        src={box}
                        style={{
                          width: "60%",
                        }}
                        alt="box"
                      />
                    </Col>
                    <Col lg={9}>
                      <h4
                        style={{
                          fontSize: "20px",
                          color: "#2F2E80",
                          fontWeight: "bold",
                        }}
                      >
                        Last Expense Funeral Cover
                      </h4>
                      <span
                        style={{
                          color: "#2F2E80",
                          fontWeight: "bold",
                          marginTop: "8px",
                        }}
                      >
                        Last Expense:
                      </span>
                      <div style={{ display: "flex" }}>
                        <ul>
                          <li style={{ color: "#2F2E80" }}>
                            <span style={{ color: "#2F2E80" }}>
                              There are two cover options, a{" "}
                              <span
                                style={{ color: "#2F2E80", fontWeight: "bold" }}
                              >
                                {" "}
                                Basic{" "}
                              </span>
                              and a{" "}
                              <span
                                style={{ color: "#2F2E80", fontWeight: "bold" }}
                              >
                                Comprehensive{" "}
                              </span>
                              option, so you can choose the one most suitable to
                              your needs and financial circumstance.
                            </span>
                          </li>
                          {data2.map((d) => (
                            <li style={{ color: "#2F2E80", marginTop: "8px" }}>
                              <span>{d}</span>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </Col>
                  </Row>
                </Col>

                <Col
                  lg={5}
                  className="d-flex justify-content-center align-items-center"
                >
                  <div
                    className="whiteoverlay_card pl-5 pr-5 pt-4 pb-4 d-flex align-items-center flex-column"
                    style={{ height: "100%" }}
                  >
                    <Row style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                      <h4
                        style={{
                          fontSize: "20px",
                          color: "white",
                          fontWeight: "bold",
                          textAlign: "center",
                          paddingLeft: "0px",
                          paddingRight: "0px",
                        }}
                      >
                        Sisekelo Funeral Plan
                      </h4>
                    </Row>
                    <Row>
                      <h4
                        style={{
                          fontSize: "10pt",
                          textAlign: "center",
                          color: "white",
                          fontWeight: "bold",
                        }}
                      >
                        For individual and family Plans <br />
                        Cover options to choose from
                        <br />
                      </h4>
                    </Row>
                    <Row>
                      <p
                        style={{
                          fontSize: "11pt",
                          textAlign: "center",
                          color: "white",
                          fontWeight: "5pt",
                        }}
                      >
                        SZL 5,000 / SZL 10,000 <br />
                        SZL 20,000 / SZL 30,000 / SZL 50,000
                      </p>
                    </Row>
                    <Row
                      className="justify-content-center"
                      style={{
                        fontSize: "11pt",
                        color: "white",
                        textAlign: "center",
                        marginBottom: "5px",
                        padding: "3px",
                        fontWeight: "bold",
                      }}
                    >
                      <span>
                        There are two cover options,{" "}
                        <span style={{ color: "#FBD105" }}>
                          a Basic and a Comprehensive option
                        </span>
                        , so you can choose the one most suitable to your needs
                        and financial circumstance.
                      </span>
                    </Row>
                    <Row
                      className="justify-content-center"
                      style={{
                        fontSize: "11pt",
                        color: "white",
                        textAlign: "center",
                        marginBottom: "5px",
                        marginTop: "11px",
                        fontWeight: "bold",
                      }}
                    >
                      <span>
                        Each option covers a policyholder, one spouse and a
                        maximum of six children with a choice to add parents and
                        parents-in-law as well as extended family members.
                      </span>
                    </Row>
                    <Row
                      className="justify-content-center"
                      style={{
                        fontSize: "11pt",
                        color: "white",
                        textAlign: "center",
                        marginBottom: "5px",
                        marginTop: "11px",
                        fontWeight: "bold",
                      }}
                    >
                      <span>
                        All valid claims are paid in less than 48 hours after
                        all the required claim documents have been submitted.
                      </span>
                    </Row>
                    <Row>
                      <Col lg={4} style={{ paddingLeft: "25px" }}>
                        <div
                          className={`card__footer__bottom  ${
                            width < 430
                              ? "mt-2 text-center"
                              : "mt-4 d-flex justify-content-start"
                          }`}
                        >
                          {width > 500 && (
                            <div
                              className={
                                width < 430
                                  ? `d-flex justify-content-center`
                                  : ""
                              }
                            >
                              <BodyButton
                                color="white"
                                bg={width > 770 ? "#FBD105" : "#FBD105"}
                                name="Buy Cover Now"
                                onClick={handleSubmit}
                                size={16}
                                weight={700}
                                marginT={0}
                                width={width > 430 ? 180 : 100}
                                borderTRR={25}
                                borderBLR={25}
                                borderBRR={25}
                                borderTLR={25}
                                black={width > 430 ? false : true}
                                isHeader={true}
                              />
                            </div>
                          )}
                        </div>
                      </Col>
                    </Row>
                    <Row
                      className="justify-content-center"
                      style={{
                        fontSize: "11pt",
                        color: "white",
                        textAlign: "center",
                        marginTop: "8px",
                        fontWeight: "bold",
                        paddingLeft: "11px",
                        marginBottom: "25px",
                      }}
                    >
                      From: SZL 21 / Monthly
                    </Row>
                    <hr
                      style={{
                        color: "white",
                        height: 1,
                        width: "97%",
                        margin: "auto",
                      }}
                    />
                    <Row
                      className="justify-content-center"
                      style={{
                        fontSize: "8pt",
                        color: "white",
                        textAlign: "center",
                        marginBottom: "5px",
                        padding: "14px",
                        fontWeight: "bold",
                      }}
                    >
                      By opting to buy insurance, Letshego will share the
                      required information with the insurer to facilitate your
                      policy purchase.
                    </Row>

                    <Row>
                      <h6
                        style={{
                          color: "white",
                          fontSize: "8pt",
                          fontWeight: "bold",
                          textAlign: "center",
                          marginBottom: "8px",
                          marginTop: "1px",
                        }}
                      >
                        Policy underwritten by
                      </h6>
                    </Row>

                    <Row>
                      <div
                        className={`d-flex ${
                          width > 770
                            ? "justify-content-start"
                            : "justify-content-center"
                        }`}
                      >
                        <img
                          src={liberty}
                          style={{
                            marginTop: "1px",
                            width: "95px",
                          }}
                          alt="Liberty Logo"
                        />
                      </div>
                    </Row>
                  </div>
                </Col>
              </Row>
            </Row>
          </div>
        </Row>
      </Col>
      {isLoading && (
        <div
          style={{
            height: "99vh",
            width: "100%",
            zIndex: 100,
            backgroundColor: "#00000050",
            position: "fixed",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            top: "0px",
          }}
        >
          <div style={{ height: 100, width: 100 }}>
            <CircularProgressbar
              styles={buildStyles({
                textColor: "#fbcf2c",
                pathColor: "#fbcf2c",
              })}
              value={countDown}
              text={`${countDown}%`}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default Card2;

const data2 = [
  "Each option covers a policyholder, one spouse and a maximum of six children with a choice to add parents and parents-in-law as well as extended family members.",
  "All valid claims are paid in less than 48 hours after all the required claim documents have been submitted."
];

